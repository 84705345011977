import { Box, Typography } from "@mui/material"
import { EXTENSIONS } from "../../helpers/requests"
import PageTitle from "../generic/PageTitle"
import { useData, useEnsureUpToDateConfigs, useTenantNavigate } from "../../helpers/CustomHooks"
import LoadingMessage from "../generic/LoadingMessage"
import { makeTicketDeepCopy } from "../../helpers/TicketFormHelper"
import React, { useMemo } from "react"
import Context from "../../Store"
import AbstractTable from "../tables/AbstractTable"
import { datetimeDisplay } from "../../helpers/FieldDisplayFormatters"
import { REVIEW } from "../../App"
import { useNavigate } from "react-router-dom"
import { getEntityTypeWithInstanceTypeId, getInstanceType } from "../../helpers/StaticDataHelper"
import { _EMP_META_LABEL } from "../../helpers/access_helper"
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage"

const getCombinedLists = (allTypesAcc, staticData) => {

    const entityTasks = [];
    const entityInstanceTypeIds = Object.keys(allTypesAcc.entity_acc).map(id => Number(id))
    entityInstanceTypeIds.forEach(entityInstanceTypeId => {
        const tasksForType = allTypesAcc.entity_acc[entityInstanceTypeId];
        const entityType = staticData.entity_types.find(et => (et.root_instance_type.id === entityInstanceTypeId) || (et.member_instance_type && et.member_instance_type.id === entityInstanceTypeId));
        const entityTypeId = entityType.id;
        const instanceType = (entityType.root_instance_type.id === entityInstanceTypeId) ? entityType.root_instance_type : entityType.member_instance_type;
        const taskTypeName = instanceType.name;
        tasksForType.forEach(task => {
            task.taskTypeName = taskTypeName;
            task.entityTypeId = entityTypeId;
            task.instanceTypeId = entityInstanceTypeId;
            entityTasks.push(task);
        })
    })

    const accessTasks = [];
    const accessTypeIds = Object.keys(allTypesAcc.access_acc).map(id => Number(id))
    accessTypeIds.forEach(accessTypeId => {
        const tasksForType = allTypesAcc.access_acc[accessTypeId];
        const accessType = staticData.access_types.find(at => at.id === accessTypeId);
        const {__meta_name, accessor_instance_type_id, resource_instance_type_id} = accessType;
        const accessorEntityType = getEntityTypeWithInstanceTypeId(staticData, accessor_instance_type_id);
        const resourceEntityType = getEntityTypeWithInstanceTypeId(staticData, resource_instance_type_id);
        // const taskTypeName = instanceType.name;
        
        tasksForType.forEach(task => {
            const accessor = task.instance.accessor;
            let accessorLabel = ""
            if(__meta_name === _EMP_META_LABEL) accessorLabel = `${staticData.all_users[accessor.id]} (Employee)`;
            else{
                accessorLabel = accessor.name;
                accessorLabel += "("
                if(accessor.parent) accessorLabel += `${accessor.parent.name}, `;
                accessorLabel += accessorEntityType.name;
                accessorLabel += ")"
            }
            task.accessorLabel = accessorLabel;
            task.resourceLabel = `${task.instance.resource.name} (${resourceEntityType.name})`;
            task.accessTypeId = accessTypeId;
            // task.taskTypeName = taskTypeName;
            // task.entityTypeId = entityTypeId;
            // task.instanceTypeId = accessTypeIds;
            accessTasks.push(task);
        })
    })


    return { entityTasks, accessTasks }
}

const EntityTasksTable = ({entityTasks}) => {
    const {staticData} = React.useContext(Context);
    const navigate = useTenantNavigate();

    const columnNames = ['Request', 'Type', 'Name', 'Root', 'Stage', 'Submitter', 'Submitted', 'At stage'];

    const renderRowCells = (task) => {
        const {instance, ticket, stage, taskTypeName, entityTypeId, instanceTypeId} = task;
        const instanceType = getInstanceType(staticData, instanceTypeId);
        const submitAction = ticket.actions.find(a => a.role === "Submitter")
        return [
            ticket.request_type,
            taskTypeName,
            instance.name,
            instance.parent ? instance.parent.name : "-",
            stage.stage_name,
            staticData.all_users[submitAction.actor_id],
            datetimeDisplay(submitAction.datetime, true),
            datetimeDisplay(stage.activation_time, true)
        ]
    }

    const onRowClick = (task) => {
        const urlExt = `/${REVIEW}/entity_type/${task.entityTypeId}/eti/${task.instance.id}/ticket/${task.ticket.id}/stage/${task.stage.stage_id}`;
        navigate(urlExt);
    }
    return (
        <AbstractTable
            rows={entityTasks}
            rowToKey={t => `${t.ticket.id}-${t.stage.stage_id}`}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            onRowClick={onRowClick}
            rowHeight={'small'}
            />
    )
}

const AccessTasksTable = ({accessTasks}) => {
    const {staticData} = React.useContext(Context);
    const navigate = useTenantNavigate();

    const columnNames = ['Request', 'For accessor', 'To resource', 'Stage', 'Submitter', 'Submitted', 'At stage'];

    const renderRowCells = (task) => {
        const {instance, ticket, stage, accessorLabel, resourceLabel, accessTypeId} = task;
        const submitAction = ticket.actions.find(a => a.role === "Submitter")
        return [
            ticket.request_type,
            accessorLabel,
            resourceLabel,
            stage.stage_name,
            staticData.all_users[submitAction.actor_id],
            datetimeDisplay(submitAction.datetime, true),
            datetimeDisplay(stage.activation_time, true)
        ]
    }

    const onRowClick = (task) => {
        const urlExt = `/${REVIEW}/access_type/${task.accessTypeId}/accessor/${task.instance.accessor.id}/resource/${task.instance.resource.id}/ticket/${task.ticket.id}/stage/${task.stage.stage_id}`;
        navigate(urlExt);
    }
    return (
        <AbstractTable
            rows={accessTasks}
            rowToKey={t => `${t.accessTypeId}-${t.ticket.id}-${t.stage.stage_id}`}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            onRowClick={onRowClick}
            rowHeight={'small'}
            />
    )
}

const PendingTasksPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const data = useData(EXTENSIONS.GET_ALL_MY_PENDING_TASKS, {})

    const combinedTasks = useMemo(() => {
        if(!data || !configSyncState) return null;
        return getCombinedLists(data, staticData);
    }, [data, configSyncState]);

    const awaitedDatas = [configSyncState, data];
    if(awaitedDatas.includes(null)) return <LoadingMessage/>
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>

    const { entityTasks, accessTasks } = combinedTasks;
    const title = "Your Pending Tasks"
    return (
        <Box key={`PendingTasksPage`}>
            <PageTitle title={title}/>
            <Typography variant="h5">Entity Tasks</Typography>
            <EntityTasksTable entityTasks={entityTasks}/>
            <Box marginTop={2}/>
            <Typography variant="h5">Access Tasks</Typography>
            <AccessTasksTable accessTasks={accessTasks}/>
            {/* <PendingTasksTabs data={processedData} staticData={staticData}/> */}
        </Box>
    )

}

export default PendingTasksPage;
