import { CHOICE_DESC_TYPES } from "../components/inputs/FieldProps";
import { FALLBACK_REVIEWER_NAME } from "./Constants";
import { sortChoicesByLabel } from "./TicketFormHelper";
import { _EMP_META_LABEL } from "./access_helper";


export const getInstanceType = (staticData, instanceTypeId) => {
    instanceTypeId = Number(instanceTypeId)
    const entityTypes = staticData.entity_types;

    for(let i = 0; i < entityTypes.length; i++){
        const et = entityTypes[i];
        if(et.root_instance_type.id === instanceTypeId) return et.root_instance_type;
        if(et.member_instance_type && et.member_instance_type.id === instanceTypeId) return et.member_instance_type
    }

    return null;
}

export const getEntityType = (staticData, entityTypeId) => {
    const entityTypes = staticData.entity_types;

    for(let i = 0; i < entityTypes.length; i++){
        const et = entityTypes[i];
        if(et.id === entityTypeId) return et;
    }

    return null;
}

export const getEntityName = (staticData, entityInstanceId) => {
    const entitySummary = staticData.entity_summaries.find(e => e.id === entityInstanceId);
    return entitySummary ? entitySummary.name : undefined;
}

export const getEntityOrUserName = (entity, entityInstanceType, userIdToNameObj) => {
    return entity.name
}

export const getStageWithId = (approvalProcess, stageId) => {

    for(let i = 0; i < approvalProcess.stages.length; i++){
        const stage = approvalProcess.stages[i];
        if(stage.id === stageId) return stage;

        if(stage.__meta_name === 'ParallelApprovalsStage'){
            for(let processIdx = 0; processIdx < stage.approval_processes.length; processIdx++){
                const subProcess = stage.approval_processes[processIdx];
                const output = getStageWithId(subProcess, stageId);
                if(output) return output;
            }
        }
    }

    return null;
}
export const getStageWithIdFromInstanceType = (instanceType, stageId) => {
    const {addition_process, removal_process, edit_processes} = instanceType;
    
    let output = null;
    output = getStageWithId(addition_process, stageId);
    if(output) return output;
    
    output = getStageWithId(removal_process, stageId);
    if(output) return output;
    
    const sectionIds = Object.keys(edit_processes);
    for(let sIdx = 0; sIdx < sectionIds.length; sIdx++){
        const sectionId = sectionIds[sIdx]
        const fieldIds = Object.keys(edit_processes[sectionId]);

        for(let fIdx = 0; fIdx < fieldIds.length; fIdx++){
            const fieldId = fieldIds[fIdx];
            const approvalProcess = edit_processes[sectionId][fieldId];
            output = getStageWithId(approvalProcess, stageId);
            if(output) return output;
        }
    }

    if(instanceType.resource_access_removal_process){
        output = getStageWithId(instanceType.resource_access_removal_process, stageId);
        if(output) return output;        
    }

    if(instanceType.mandatory_removal_process){
        output = getStageWithId(instanceType.mandatory_removal_process, stageId);
        if(output) return output;        
    }
    // console.log(`getStageWithIdFromInstanceType`, {instanceType, stageId})
    return null;
}

export const getEntityInstanceTypeById = (staticData, instanceTypeId) => {
    const entityTypes = staticData.entity_types;
    for(let i = 0; i < entityTypes.length; i++){
        const et = entityTypes[i];
        if(et.root_instance_type.id === instanceTypeId) return et.root_instance_type;
        if(et.member_instance_type && et.member_instance_type.id === instanceTypeId) return et.member_instance_type;
    }
    return null;
}

export const getEntityTypeWithInstanceTypeId = (staticData, instanceTypeId) => {
    const entityTypes = staticData.entity_types;
    for(let i = 0; i < entityTypes.length; i++){
        const et = entityTypes[i];
        if(et.root_instance_type.id === instanceTypeId) return et;
        if(et.member_instance_type && et.member_instance_type.id === instanceTypeId) return et;
    }
    return null;
}

export const getAllEntityChoices = (staticData, instanceTypeId, parentId) => {
    const entityType = getEntityTypeWithInstanceTypeId(staticData, instanceTypeId);
    const entityTypeId = entityType.id;

    const {entity_summaries} = staticData;

    const choices = [];
    entity_summaries.forEach(entitySummary => {
        if((entitySummary.entity_type_id === entityTypeId) && (entitySummary.parent_id === parentId)){
            choices.push({value: entitySummary.id, label: entitySummary.name})
        }
    })
    sortChoicesByLabel(choices)
    return choices;
}

export const getActiveEntityChoices = (staticData, instanceTypeId, parentId) => {
    const entityType = getEntityTypeWithInstanceTypeId(staticData, instanceTypeId);
    const entityTypeId = entityType.id;

    const {entity_summaries} = staticData;

    const choices = [];
    entity_summaries.forEach(entitySummary => {
        if(entitySummary.is_active && (entitySummary.entity_type_id === entityTypeId) && (entitySummary.parent_id === parentId)){
            choices.push({value: entitySummary.id, label: entitySummary.name})
        }
    })
    sortChoicesByLabel(choices)
    return choices;
}
export const getActiveEmpChoices = (staticData) => {
    const output = []
    for (const [id, name] of Object.entries(staticData.active_users)) {
        output.push({value: Number(id), label: name})
    }
    output.sort((a, b) => a.label.localeCompare(b.label));
    return output;
}

export const isAccessTypeForEmployee = (accessType) => {
    return accessType.__meta_name === _EMP_META_LABEL;
}

export const getEntitySummaryFor = (staticData, entityTypeId, parentId, name) => {
    [].toString().toLowerCase()
    const entitySummary = staticData.entity_summaries.find(es => {
        return (es.entity_type_id === entityTypeId)
            && (es.parent_id === parentId)
            && (es.name.toString().toString().toLowerCase() === name.toString().toLowerCase())
    })
    return entitySummary;
}

export const getFieldStructureFromFormStructureWithIds = (formStructure, sectionId, fieldId) =>{
    return getFieldStructureFromFormStructure(formStructure, {section_key: sectionId, field_key: fieldId})
}
export const getFieldStructureFromFormStructure = (formStructure, fieldDesc) => {
    const sectionStructure = formStructure.sections.find(s => s.id === fieldDesc.section_key);
    if(!sectionStructure) return null;
    const fieldStructure = sectionStructure.fields.find(f => f.id === fieldDesc.field_key);
    return fieldStructure;
}
export const getFieldFromStructureWithId = (formStructure, fieldId) => {
    for(const s of formStructure.sections){
        for(const f of s.fields){
            if(f.id === fieldId) return f;
        }
    }
    return null;
}

export const getSectionWithWithId = (structure, fieldId) => {
    for(const section of structure.sections){
        for(const field of section.fields){
            if(field.id === fieldId) return section;
        }
    }
    return null;
}

export const sortEntitiesOfTypeByName = (entities) => {
    entities.sort((a, b) => a.name.localeCompare(b.name))
}

export const sortEntitiesByParentThenOwnName = (entities) => {
    entities.sort((a, b) => {
        let aName = a.name;
        if(a.parent) aName = a.parent.name + ' ' + aName;
        let bName = b.name;
        if(b.parent) bName = b.parent.name + ' ' + bName;
        aName.localeCompare(bName)
    })
}

export const getActiveUserChoices = (staticData) => {
    const usersMap = staticData.active_users;
    const choices = Object.entries(usersMap).map(([userIdStr, usersName]) => {return {value: Number(userIdStr), label: usersName}});
    // console.log('active user choices: ', choices);
    choices.sort((a, b) => a.label.localeCompare(b.label));
    return choices;
}

export const getActiveDepartmentChoices = (staticData) => {
    const departmentsMap = staticData.active_departments;
    const choices = Object.entries(departmentsMap).map(([deptIdStr, deptsName]) => {return {value: Number(deptIdStr), label: deptsName}});
    choices.sort((a, b) => a.label.localeCompare(b.label));
    return choices;
}

/**return all entity INSTANCE types and Access types */
const getALlInstanceTypes = (staticData) => {
    const output = [];
    for(const et of staticData.entity_types){
        output.push(et.root_instance_type);
        if(et.member_instance_type) output.push(et.member_instance_type)
    }
    for(const at of staticData.access_types){
        output.push(at)
    }
    return output;
}
export const sortFieldChoicesInSection = (sectionStructure) => {
    if(sectionStructure.is_deleted) return;

    for(const fieldStructure of sectionStructure.fields){
        if(!fieldStructure.is_deleted && fieldStructure.choice_desc && fieldStructure.choice_desc.__meta_type !== CHOICE_DESC_TYPES.STATIC){
            sortChoicesByLabel(fieldStructure.choice_desc.choices)
        }
    }
}

export const sortChoicesInStaticData = (staticData) => {
    const instanceTypes = getALlInstanceTypes(staticData);
    for(const instanceType of instanceTypes){
        for(const sectionStructure of instanceType.structure.sections){
            sortFieldChoicesInSection(sectionStructure);
        }
    }
}

export const setAccessTypesRelatedTypes = (staticData) => {
    for(const accessType of staticData.access_types){
        const {accessor_instance_type_id, resource_instance_type_id} = accessType;

        const resourceType = getInstanceType(staticData, resource_instance_type_id);
        accessType.resource_type = resourceType;
        
        let accessorTypeName = "Employee";
        if(accessor_instance_type_id){
            const accessorType = getInstanceType(staticData, accessor_instance_type_id);
            accessType.accessor_type = accessorType;
            accessorTypeName = accessorType.name;
        }

        accessType.name = `${accessorTypeName} → ${resourceType.name}`
    }
}

export const getEntitySummariesOfInstanceType = (staticData, instanceTypeId) => {
    const output = [];
    const entityType = getEntityTypeWithInstanceTypeId(staticData, instanceTypeId);
    const isRoot = instanceTypeId === entityType.root_instance_type.id;

    for(const entitySummary of staticData.entity_summaries){
        const {id, entity_type_id, name, is_active, parent_id, parent_name} = entitySummary;
        const entityIsRoot = !parent_id;
        if((entity_type_id === entityType.id) && (entityIsRoot === isRoot)){
            output.push(entitySummary);
        }
    }
    return output;
}

export const getEmployeeNameWithId = (staticData, empId) => {
    if(staticData?.all_users?.[empId]){
        return staticData.all_users[empId]
    }
    else return FALLBACK_REVIEWER_NAME
}