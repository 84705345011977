import { Box, Typography } from "@mui/material"
import React, { Fragment } from "react"
import { FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../components/inputs/FieldProps"
import { multiValueToArray } from "./TicketFormHelper"

export const datetimeDisplay = (s, ignoreTime) => {
    //format = YYYY-MM-DDTHH:mm:ss.xxxZ
    const [date, time] = s.split("T")

    const [year, month, day] = date.split("-")
    const [hour, minute, junk] = time.split(":")

    if(ignoreTime) return` ${monthToName(month)} ${day}, ${year}`
    return `${monthToName(month)} ${day}, ${year} ${hour}:${minute} UTC`
}

const monthToName = (monthNumStr) => {
    switch (monthNumStr) {
        case "01": return "Jan"
        case "02": return "Feb"
        case "03": return "Mar"
        case "04": return "Apr"
        case "05": return "May"
        case "06": return "June"
        case "07": return "July"
        case "08": return "Aug"
        case "09": return "Sept"
        case "10": return "Oct"
        case "11": return "Nov"
        case "12": return "Dec"

        default: return "???"
    
    }
}

export const multiLineStringToJSX = (message) => {
    return message.split("\n").map((s, idx) =>{
        return(
          <Fragment key={`feedback-line-${idx}`}>
            {s}
            <br/>
          </Fragment>
        )
      })
}

const numLeadingSpaces = (s) => {
  // const spacesPerIndent = 4
  for(let i = 0; i < s.length; i++){
    if(s[i] !== " "){
      return i
    }
  }

  return s.length
}

export const canonicalTextToJSX = (txt) => {

  const marginPerSpace = 1
  const elements = []

  const lines = txt.split("\n")
  for(let i = 0; i < lines.length; i++){
    const line = lines[i]
    const m = marginPerSpace * numLeadingSpaces(line)
    if(line === "") elements.push(<br key={i}/>)
    else elements.push(<Typography marginLeft={m} key={i}>{line}</Typography>)
    // elements.push(<br/>)
  }

  return elements

}


const selectValueToLabelRec = (value, choices) => {
  if(Array.isArray(choices)) {
    const choice = choices.find( choice => choice.value === value);
    return choice ? choice.label : null;
  }

  for(const depVal in choices){
    const subChoices = choices[depVal];
    const label = selectValueToLabelRec(value, subChoices);
    if(label) return label;
  }

  return null;
}

export const selectValueToLabel = (value, choices) => {
  const label = selectValueToLabelRec(value, choices);
  return label ? label : value;
}

export const fieldValueString = (fieldValue, fieldStructure, staticData) => {
  //#TODO: refector references to not provide the unused params.
  //need to think if the section values are needed for Select compnents with dependencies
  if((fieldValue === null) || (fieldValue === undefined) || (fieldValue === "")) return "none";
  if(!fieldStructure) return `${fieldValue}`;

  if(fieldValue === true) return "Yes"
  if(fieldValue === false) return "No"
  
  if(fieldStructure.value_type == FIELD_VALUE_TYPES.EMPLOYEE) return staticData.all_users[fieldValue];
  if(fieldStructure.value_type == FIELD_VALUE_TYPES.DEPARTMENT) return staticData.all_departments[fieldValue];
  
  const component = fieldStructure.component

  if(component === FIELD_COMPONENT_TYPES.SELECT){
    return selectValueToLabel(fieldValue, fieldStructure.choice_desc.choices)
  }

  if([FIELD_COMPONENT_TYPES.MULTISELECT, FIELD_COMPONENT_TYPES.MULTICHECK].includes(component)){
      let choices = fieldStructure.choice_desc.choices;
      const allVals = multiValueToArray(fieldValue);
      const labels = allVals.map(val => {
          const choice = choices.find( choice => choice.value === val);
          return (choice !== undefined) ? choice.label.toString() : val.toString();          
      });
      return labels.join(", ")
  }

  if(component === FIELD_COMPONENT_TYPES.DATEPICKER) return datetimeDisplay(fieldValue);

  if(component === FIELD_COMPONENT_TYPES.MULTI_TEXT) return fieldValue.replaceAll('\n', ', ')
  return fieldValue.toString();
}
