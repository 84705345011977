import { useContext, useState } from "react";
import { getReviewerIdsOfAllStages, getReviewerIdsOfRemainingStages } from "../../helpers/process_helper"
import Context from "../../Store";
import { Box, Stack, Typography } from "@mui/material";
import { getEmployeeNameWithId } from "../../helpers/StaticDataHelper";
import { Button, Divider } from "../../../node_modules/@mui/material/index";


const VIEW_TOGLLE_MARGIN = 8;

export const AllReviewersOfTicket = ({instanceType, instance, ticket}) => {
    const {staticData} = useContext(Context);
    const [isDisplayingUniqueOnly, setIsDisplayingUniqueOnly] = useState(false)

    const stagesWithReviewerIds = getReviewerIdsOfAllStages(instance, ticket, staticData, instanceType);
    // const stagesWithReviewerIds = [...xstagesWithReviewerIds, ...xstagesWithReviewerIds, ...xstagesWithReviewerIds]

    if(isDisplayingUniqueOnly){
        let allReviewersIds = []
        stagesWithReviewerIds.forEach(({reviewerIds}) => allReviewersIds.push(...reviewerIds))
        allReviewersIds = [...new Set(allReviewersIds)]
        return (
            <Box>
                <Stack direction='row' spacing={VIEW_TOGLLE_MARGIN}>
                    <Typography variant='h6'>
                        All reviewers of ticket:
                    </Typography>
                    <Button onClick={()=>setIsDisplayingUniqueOnly(!isDisplayingUniqueOnly)} variant='outlined'>
                        Details
                    </Button>
                </Stack>
                <Stack marginLeft={2}>
                    {
                        allReviewersIds.map(reviewerId => {
                            const empName = getEmployeeNameWithId(staticData, reviewerId);
                            return (
                                <Typography key={reviewerId}>
                                    {empName}
                                </Typography>
                            )
                        })
                    }
                </Stack>
            </Box>         
        )
    }

    return (
        <Box>
            <Stack direction='row' spacing={VIEW_TOGLLE_MARGIN}>
                <Typography variant='h6'>
                    All stages and reviewers:
                </Typography>
                <Button onClick={()=>setIsDisplayingUniqueOnly(!isDisplayingUniqueOnly)} variant='outlined'>
                    Only Reviewers
                </Button>
            </Stack>
            <Stack marginLeft={2} direction='row' spacing={2} p={1} divider={<Divider flexItem orientation="vertical" sx={{ bgcolor: "#eeeeee", margin: -3}}/>}>
                {
                    stagesWithReviewerIds.map(({stage, reviewerIds}) => {
                        return (
                            <Box key={stage.id}>
                                <Typography variant='h6'>{stage.name}</Typography>
                                <Stack marginLeft={0}>
                                    {
                                        reviewerIds.map(reviewerId => {
                                            const empName = getEmployeeNameWithId(staticData, reviewerId);
                                            return (
                                                <Typography key={reviewerId}>
                                                    {empName}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}