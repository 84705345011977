import { Box, Divider, Stack, Typography } from '@mui/material';
import React, {Fragment} from 'react';
import CollapseableContent from '../generic/CollapseableContent';
import { datetimeDisplay } from '../../helpers/FieldDisplayFormatters';
import Context from '../../Store';
import LabelValueDisplay from '../generic/LabelValueDisplay';
import TicketView from '../instance/TicketView';
import { getStageWithId } from '../../helpers/StaticDataHelper';
import { REQUEST_STATES } from '../../helpers/Constants';
import { EXTENSIONS, downloadEntityFileField, post } from '../../helpers/requests';
import { hasGobalPermission, hasInstancePermissions } from '../permissions/perm_helper';
import { ALL_PERMS } from '../permissions/perm_constants';
import MultiSectionForm from '../form/MultiSectionForm';
import { CONTEXT_DEPENDENCIES } from '../../helpers/TicketFormHelper';
import ViewRelatedAccessPanelButton from '../access/RelatedAccessPanel';
import { getPendingActivenessTicket } from '../../helpers/CommonUtil';
import { Button, Tooltip } from '../../../node_modules/@mui/material/index';
import { EDIT, VIEW } from '../../App';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useTenantNavigate } from '../../helpers/CustomHooks';
// import RelatedAccessPanel from '../access/RelatedAccessPanel';



//old props - ticket, structure, getSectionFieldSetter, editable_sections
const EntityView = ({entity, entityType, refetchEntity}) =>{
    const {staticData, alertSuccess, alertError, user} = React.useContext(Context);
    const {all_users} = staticData;
    const {is_active, tickets} = entity;
    const isRoot = !entity.parent;
    const entityInstanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;

    const {structure} = entityInstanceType;

    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const navigate = useTenantNavigate()

    const hasPendingTicket = entity.tickets.some(t => t.status === "Pending");
    const canUserCancelTickets = hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.CANCEL_REQ.key, entity, entityInstanceType);
    
    //requires global perm
    // or user holds role on this instance (or parent of) AND that role can view access (need to well-define what that means)
    const canViewRelatedAccess = undefined;

    const onCancelClick = (ticket, message) => {
        if(!message){
            alertError("Provide a reason for cancling the request.");
            return;
        }
        const body = {
            instance_desc: {
                instance_type: "Entity",
                entity_instance_id: entity.id
            },
            ticket_id: ticket.id,
            msg: message
        }
        const onSuccess = (response) => {
            alertSuccess("Request has been canceled");
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            refetchEntity()
        }
        const onFail = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.FAILED)
        }
        post(EXTENSIONS.CANCEL_TICKET, body, onSuccess, onFail)
        setSubmitState(REQUEST_STATES.SENDING)
    }

    const downloadFileGetter = (ticketId) => {
        return (sectionId) => {
            return (fieldId) => {
                downloadEntityFileField(entity.id, ticketId, sectionId, fieldId, null);
                // downloadEntityFileField(entityId, ticketId, sectionId, fieldId, stageId);
            }
        }
    }

    const parentDownloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadEntityFileField(entity.parent.id, null, sectionId, fieldId, null);
            // downloadEntityFileField(entityId, ticketId, sectionId, fieldId, stageId);
        }
    }

    const pendingActivenessTicket = getPendingActivenessTicket(entity);
    const canEditTickets = hasGobalPermission(user, ALL_PERMS.ENTITY_ACTIONS.OVERRIDE_EDIT.key);
    const onEditTicketClick = () => {
        const urlExt = `/${EDIT}/entity_type/${entityType.id}/eti/${entity.id}`
        navigate(urlExt);
    }
    return(
        <Box maxWidth={1200} overflow={"auto"}>
            {
                !is_active ? null :
                <>
                    <CollapseableContent title={`Current state of ${entity.name}`} isExpandedByDefault={true}>
                        {/* <FormValuesView formValues={entity.state.form_values} structure={structure}/> */}
                        <MultiSectionForm
                            formData={entity.state.form_values}
                            structure={structure}
                            getSectionDownloadFileField={downloadFileGetter(null)}
                            contextValues={{[CONTEXT_DEPENDENCIES.EMP_ID_ON_ENTITY]: entity.name}}
                            />
                    </CollapseableContent>
                    {
                        isRoot ? null :
                        <CollapseableContent title={`${entity.parent.name} (${entityInstanceType.parent_type.name} group)`} isExpandedByDefault={false}>
                            <MultiSectionForm
                                formData={entity.parent.state.form_values}
                                structure={entityInstanceType.parent_type.structure}
                                getSectionDownloadFileField={parentDownloadFileGetter}
                                contextValues={{[CONTEXT_DEPENDENCIES.EMP_ID_ON_ENTITY]: entity.parent.name}}
                                />                        
                        </CollapseableContent>

                    }
                </>
            }
            {
                !(pendingActivenessTicket && canEditTickets) ? null :
                <Tooltip title={'Edit the pending ticket for adding/reopening this entity'}>
                    <Button onClick={onEditTicketClick} variant='contained' sx={{marginY:2}}>
                        Edit Ticket
                    </Button>
                </Tooltip>
            }
            {
                <CollapseableContent title={"Tickets"} isExpandedByDefault={!is_active && hasPendingTicket}>
                    {
                        entity.tickets.map(t => {
                            const isPending = (t.status === "Pending");
                            const headerColor = isPending ? 'primary.light' : undefined;
                            const title = `${t.request_type} (${t.status})`;
                            return(
                                <CollapseableContent isExpandedByDefault={!is_active && isPending} key={t.id} headerColor={headerColor} title={title}>
                                    <TicketView
                                        ticket={t}
                                        structure={structure}
                                        allUsersMap={all_users}
                                        key={t.id}
                                        onCancelClick={onCancelClick}
                                        canUserCancel={canUserCancelTickets}
                                        getSectionDownloadFileField={downloadFileGetter(t.id)}
                                        contextValues={{[CONTEXT_DEPENDENCIES.EMP_ID_ON_ENTITY]: entity.name}}
                                        />
                                </CollapseableContent>
                            )
                        })
                    }
                </CollapseableContent>
            }

            <ViewRelatedAccessPanelButton entity={entity} entityInstanceType={entityInstanceType}/>
            <Box height={300}></Box>
        </Box>
    )
}

export default EntityView