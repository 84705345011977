import { Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material"
import LabelValueDisplay from "../generic/LabelValueDisplay";
import { isAccessTypeForEmployee } from "../../helpers/StaticDataHelper";

const getEntityStatusNote = (entity) => {
    if(entity.is_active) return 'Active';
    else return 'Not Active'
}
const getAccessorStatusNote = (accessInstance, accessType) => {
    const isAccessorEmp = isAccessTypeForEmployee(accessType);
    const accessor = accessInstance.accessor;

    if(isAccessorEmp) return accessor.status;
    else return getEntityStatusNote(accessor)
}

const AccessHeader = ({title, subtitle, accessInstance, accessType}) => {
    
    const accessorText = `${accessInstance.accessor.name} (${getAccessorStatusNote(accessInstance, accessType)})`;
    const resourceText = `${accessInstance.resource.name} (${getEntityStatusNote(accessInstance.resource)})`;
    return (
        <Box marginBottom={2}>
            <Typography variant="h5">{title}</Typography>
            {!subtitle ? null : <Typography variant="h6">{subtitle}</Typography>}
            <LabelValueDisplay label={"For"} value={accessorText}/>
            <LabelValueDisplay label={"To"} value={resourceText}/>
            {/* <LabelValueDisplay label={"For"} value={accessInstance.accessor.name}/>
            <LabelValueDisplay label={"To"} value={accessInstance.resource.name}/> */}
        </Box>
    )
}

export default AccessHeader;