import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useData } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import React, { useState } from "react";
import AbstractTable from "../tables/AbstractTable";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import { HRIS_VIEWS } from "./hris_constants";
import { useSearchParams } from "react-router-dom";
import { hasGobalPermission } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";

const RolesTable = ({empRoles}) => {
    const {user} = React.useContext(Context);

    const shouldDisplayEmpStatus = hasGobalPermission(user, ALL_PERMS.ORG_ACTIONS.EDIT_ORG.key);

    const columnNames = [
        'Employee',
        shouldDisplayEmpStatus ? 'Status' : ""
    ]

    const renderRowCells = (empRole) => {
        const {employee, role} = empRole;
        // const { __meta_type, id, email, first_name, last_name, name, status, extra_data, role_tags } = employee
        const { id, extra_data } = role;
        return [
            employee.name,
            shouldDisplayEmpStatus ? employee.status : ""
        ]
    }

    return (
        <AbstractTable
            rows={empRoles}
            rowToKey={empRole => empRole.role.id}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            rowHeight={"small"}
            />
    )
}

const DepartmentRow = ({department, refetchCompany}) => {
    const { id, type, name, manager, extra_data, children, employee_roles } = department;
    const [isExpanded, setIsExpanded] = useState(false)
    const s = ">"
    const managerLabel = manager ? manager.employee.name : "No Manager"
    return (
        <Box>
            <Stack direction={'row'}>
                <Button onClick={() => setIsExpanded(!isExpanded)}>{s}</Button>
                <Typography alignContent={"center"}>{name} ({managerLabel})</Typography>
            </Stack>
            {
                !isExpanded ? null :
                <Box marginLeft={2}>
                    {!children.length ? null : <DepartmentsList departments={children} refetchCompany={refetchCompany}/>}
                    {!employee_roles.length ? null :<RolesTable empRoles={employee_roles}/>}
                </Box>
            }
        </Box>
    )
}

const DepartmentsList = ({departments, refetchCompany}) => {
    // const { id, type, name, manager, extra_data, children, employee_roles } = department;
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [searchParams, setSearchParams] = useSearchParams();

    const onEditClick = (departmentId) => {
        setSearchParams({view: HRIS_VIEWS.EDIT_DEPT, dept_id: departmentId})
    }

    const onDeleteClick = (departmentId) => {
        const body = {department_id: departmentId};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Department deleted")
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.REMOVE_DEPT, body, onSuccess, onFailure)
    }

    const renderRowCells = d => {
        return [
            <DepartmentRow department={d} refetchCompany={refetchCompany}/>,
            <ButtonGroup variant="contained">
                <Button color="primary" onClick={() => onEditClick(d.id)}>
                    Edit
                </Button>
                <Button color="error" onClick={() => onDeleteClick(d.id)}>
                    Delete
                </Button>
            </ButtonGroup>
        ]
    }
    return (
        <AbstractTable
            rows={departments}
            rowToKey={r => r.id}
            columnNames={['Department', '']}
            renderRowCells={renderRowCells}
            rowHeight={"small"}
            />

    ) 
}

const OrgView = ({company, refetchCompany}) => {
    const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;
    return (
        <DepartmentsList departments={root_department.children} refetchCompany={refetchCompany}/>
    )
}

export default OrgView;