import { useContext, useEffect, useState } from "react"
import Context from "../../Store"
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import EditIcon from '@mui/icons-material/Edit';
import LoadingMessage from "../generic/LoadingMessage";
import { useNavigate, useParams, useSearchParams } from "../../../node_modules/react-router-dom/dist/index";
import { InstanceTypeConfigPage } from "./InstanceTypeConfigPage";
import { INSTANCE_TYPES } from "../../helpers/Constants";
import { useRefetchData, useTenantNavigate } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { setAccessTypesRelatedTypes } from "../../helpers/StaticDataHelper";
import PageTitle from "../generic/PageTitle";
import { MyDivider } from "../generic/MyDivider";
import { ConfigLockTimer } from "./ConfigLockTimer";
import { CreateAccessTypeDialog } from "./CreateAccessTypeDialog";
import { CreateEntityInstanceTypeDialog } from "./CreateEntityInstanceTypeDialog";
import { ActivateTypeConfirmationDialog } from "./ActivateTypeConfirmationDialog";
import { RemoveTypeConfirmationDialog } from "./RemoveTypeConfirmationDialog";

const getAllInstanceTypes = (entityTypes) => {
    const output = [];
    for(const et of entityTypes){
        output.push(et.root_instance_type)
        if(et.member_instance_type) output.push(et.member_instance_type)
    }
    return output;
}

const getInstanceTypeDescriptorFor = (instanceTypeId, entityTypes, accessTypes) => {
    if(!instanceTypeId) return null;
    for(const at of accessTypes){
        if(at.id === instanceTypeId){
            return {
                instance_type: INSTANCE_TYPES.ACCESS,
                entity_type_id: null,
                instance_type_id: instanceTypeId,
            }
        }
    }
    for(const et of entityTypes){
        if((et.root_instance_type.id === instanceTypeId) || (et.member_instance_type && (et.member_instance_type.id === instanceTypeId))){
            return {
                instance_type: INSTANCE_TYPES.ENTITY,
                entity_type_id: et.id,
                instance_type_id: instanceTypeId,
            }
        }
    }
    return null;
}


const TitleWithAddButton = ({title, tooltip, onClick}) => {
    return (
        <Typography variant='h6'>
            {title}
            <Tooltip title={tooltip} enterDelay={750}>
                <IconButton onClick={onClick}>
                    <AddCircleIcon color='primary'/>
                </IconButton>
            </Tooltip>
        </Typography>
    )
}

const InstanceTypeMenuItem = ({instanceType, instanceTypeDescriptor, refetchConfigData}) => {
    const {alertError, alertSuccess} = useContext(Context);
    const navigate = useTenantNavigate();
    
    const [isActivationPanelOpen, setIsActivationPanelOpen] = useState(false);
    const [isRemovalPanelOpen, setIsRemovalPanelOpen] = useState(false);

    const closePanels = () => {
        setIsActivationPanelOpen(null);
        setIsRemovalPanelOpen(null);
    }

    const onEditInstanceTypeClick = (instanceType) => {
        navigate(`instance_type_id/${instanceType.id}`);
    }


    return (
        <Stack key={instanceType.id} direction='row' alignItems='center' spacing={1}>
            {/* {
                !isActivationPanelOpen ? null :
                <ActivateTypeConfirmationDialog instanceTypeDescriptor={instanceTypeDescriptor} close={closePanels} onActivated={refetchConfigData}/>
            }
            {
                !isRemovalPanelOpen ? null :
                <RemoveTypeConfirmationDialog instanceTypeDescriptor={instanceTypeDescriptor} close={closePanels} onRemoved={refetchConfigData}/>
            } */}
            <IconButton onClick={() => onEditInstanceTypeClick(instanceType)}>
                <EditIcon color='primary'/>
            </IconButton>
            <Typography minWidth={50}>{instanceType.name} {instanceType.is_active ? "" : "(Inactive)"}</Typography>
            {/* {
                (instanceType.is_active) ? null :
                    <>
                        <Button onClick={() => setIsActivationPanelOpen(true)} variant='contained' size='small'>
                            Activate
                        </Button>
                        <Button onClick={() => setIsRemovalPanelOpen(true)} color={'error'} variant='contained' size='small'>
                            Remove
                        </Button>
                    </>
            } */}
        </Stack>
    )
}

export const ConfigPage = () => {
    //TODO: obtain lock. If fail, then allow user to continue with warning?
    //TODO: fetch fresh version of entity_types, access_types, tags_map (dont need all the static data, especially the entity summaries)
    const {alertError, alertSuccess} = useContext(Context);

    const onConfigDataFetched = (resp) => setAccessTypesRelatedTypes(resp.data);
    const [configData, refetchConfigData] = useRefetchData(EXTENSIONS.GET_CONFIG_META, {}, onConfigDataFetched);
    const [userTagMapData, refetchUserTagMapData] = useRefetchData(EXTENSIONS.GET_EMP_TAGS, {});

    const onFailureToAcquireLock = (e) => {
        alertError("Failed to obtain the config lock. Someone else may be editing the configurations. try again later.");
    }
    const [isConfigLockHeld, refetchIsConfigLockHeld] = useRefetchData(EXTENSIONS.OBTAIN_CONFIG_LOCK, {}, undefined, onFailureToAcquireLock);
    const [openCreationPanel, setOpenCreationPanel] = useState(null);

    const closePanels = () => setOpenCreationPanel(null);

    const navigate = useTenantNavigate();
    const params = useParams()

    const instanceTypeId  = Number(params.instance_type_id)
    const attribute = params.attr;
    const processRequestType = params.process_type;
    const editFieldId = params.editFieldId;

    const awaitedDatas = [configData, userTagMapData, isConfigLockHeld];
    if(awaitedDatas.includes(false)) return <div>Failed to Load</div>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const userTags = userTagMapData ? Object.keys(userTagMapData).map(tagId => ({id: Number(tagId), label: userTagMapData[tagId].label})) : [];
    const {entity_types, access_types, company_funcs, choices_per_company_func} = configData; 

    const allEntityInstanceTypes = getAllInstanceTypes(entity_types);

    if(instanceTypeId){
        const instanceType = allEntityInstanceTypes.concat(access_types).find(it => it.id === instanceTypeId);
        if(!instanceType){
            return <div>Page not found (Bad ID parameter)</div>
        }
        const instanceTypeDescriptor = getInstanceTypeDescriptorFor(instanceTypeId, entity_types, access_types)
        return (
            <InstanceTypeConfigPage
                instanceType={instanceType}
                instanceTypeDescriptor={instanceTypeDescriptor}
                userTags={userTags}
                refetchTags={refetchUserTagMapData}
                configData={configData}
                refetchConfigs={refetchConfigData}
                />
        )
    }

    
    return (
        <Box>
            {
                (openCreationPanel !== INSTANCE_TYPES.ACCESS) ? null :
                <CreateAccessTypeDialog entityInstanceTypes={allEntityInstanceTypes} accessTypes={access_types} close={closePanels} onCreated={refetchConfigData}/>
            }
            {
                (openCreationPanel !== INSTANCE_TYPES.ENTITY) ? null :
                <CreateEntityInstanceTypeDialog entityInstanceTypes={allEntityInstanceTypes} close={closePanels} onCreated={refetchConfigData}/>
            }
            <PageTitle title={"System Configurations"}/>
            <Box justifyContent='center' display='flex' marginTop={-4}>
                <ConfigLockTimer/>
            </Box>
            <MyDivider/>
            <Box>
                <TitleWithAddButton
                    title={'Entity'}
                    tooltip={'create new entity type'}
                    onClick={() => setOpenCreationPanel(INSTANCE_TYPES.ENTITY)}
                    />
                <Stack marginLeft={4}>
                    {
                        allEntityInstanceTypes.map(eit => {
                            return (
                                <InstanceTypeMenuItem
                                    key={eit.id}
                                    instanceType={eit}
                                    instanceTypeDescriptor={getInstanceTypeDescriptorFor(eit.id, entity_types, access_types)}
                                    refetchConfigData={refetchConfigData}
                                    />
                            )
                        })
                    }
                </Stack>
            </Box>
            <MyDivider/>
            <Box>
                <TitleWithAddButton
                    title={'Access'}
                    tooltip={'Define a new access type'}
                    onClick={() => setOpenCreationPanel(INSTANCE_TYPES.ACCESS)}
                    />
                <Stack marginLeft={4}>
                    {
                        access_types.map(at => {
                            return (
                                <InstanceTypeMenuItem
                                    key={at.id}
                                    instanceType={at}
                                    instanceTypeDescriptor={getInstanceTypeDescriptorFor(at.id, entity_types, access_types)}
                                    refetchConfigData={refetchConfigData}
                                    />
                            )
                        })
                    }
                </Stack>
            </Box>
            <MyDivider/>
        </Box>
    )
}