import { Box, Button, Stack, Typography} from '@mui/material';
import React, { useEffect, useState} from 'react';
import { EXTENSIONS, downloadAccessFileField, post } from '../../helpers/requests';
import { cleanForm, formatFormValues, makeNestedDeepCopy, makeTicketDeepCopy } from '../../helpers/TicketFormHelper';
import ResponseDialog, { DEFER } from '../instance/ResponseDialog';
import { REQUEST_STATES, UNABORTABLE_REQUEST_TYPES } from '../../helpers/Constants';
import Context from '../../Store';
import CollapseableContent from '../generic/CollapseableContent';
import TicketView from '../instance/TicketView';
import { getStageWithId, getStageWithIdFromInstanceType } from '../../helpers/StaticDataHelper';
import ActionsPanel from '../instance/ActionsPanel';
import { REVIEW } from '../../App';
import ConfirmActionDialog from '../generic/ConfirmActionDialog';
import { PENDING_TASKS, TASKS } from '../app/LayeredNavPanel';
import { useNavigate } from 'react-router-dom';
import { ACCESS_REQUEST_TYPES, isAccessTypeForEmp } from '../../helpers/access_helper';
import AccessRelatedEntitiesStates from './AccessRelatedEntitiesStates';
import { hasInstancePermissions } from '../permissions/perm_helper';
import { ALL_PERMS } from '../permissions/perm_constants';
import MultiSectionForm from '../form/MultiSectionForm';
import LabelValueDisplay from '../generic/LabelValueDisplay';
import { getPendingEditSummary } from '../../helpers/CommonUtil';
import { useTenantNavigate } from '../../helpers/CustomHooks';

const msgPlaceholderText = `Enter a message for ticket submitter and future reviewers. 
If rejecting, the message will also be sent to any previous approvers`

const ApproverAccessTicketReviewDirect = ({accessTypeId, task, structure}) =>{
    const navigate = useTenantNavigate();
    const {staticData, alertSuccess, alertError, user} = React.useContext(Context);
    const {all_users} = staticData;

    const {instance, stage} = task;
    const [ticket, setTicket] = useState(task.ticket);

    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);
    const [submitResponse, setSubmitResponse] = useState(null);

    const accessType = staticData.access_types.find(at => at.id === accessTypeId);
    const stageConfig = getStageWithIdFromInstanceType(accessType, stage.stage_id);
    if(!stageConfig){
        return <div>Something went wrong. Try refreshing or try again later.</div>
    }
    const {editable_sections, confirmation_messages} = stageConfig;

    const editableSectionLabels = editable_sections.map(sId => structure.sections.find(s => s.id===sId).metadata.label)
    const isEmpAccess = isAccessTypeForEmp(accessType);
    const canReject = !UNABORTABLE_REQUEST_TYPES.includes(ticket.request_type);

    const onConfirmClick = (action, msg, deferValue) => {
        const formDataCopy = makeTicketDeepCopy(ticket.form_values);
        // if(action === DEFER) formDataCopy[stageConfig.section_key][stageConfig.field_key] = deferValue;

        if(ticket.request_type === ACCESS_REQUEST_TYPES.GRANT){
            formatFormValues(formDataCopy, structure);
        }

        const body = {
            instance_desc: {
                instance_type: "Access",
                is_emp_access: isEmpAccess,
                accessor_id: instance.accessor.id,
                resource_id: instance.resource.id
            },
            ticket_id: ticket.id,
            stage_id: stage.stage_id,
            action_type: action,
            msg: msg,
            edited_form: formDataCopy
        }

        const onSuccess = (response) => {
            alertSuccess(response.data.message);
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            setSubmitResponse(response.data)
        }
        const onFail = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        post(EXTENSIONS.REVIEW_TICKET, body, onSuccess, onFail)
        setSubmitState(REQUEST_STATES.SENDING)
    }

    const currTicketDownloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadAccessFileField(isEmpAccess, instance.accessor.id, instance.resource.id, ticket.id, sectionId, fieldId, stage.stage_id);
        }
    }
    const accessStateDownloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadAccessFileField(isEmpAccess, instance.accessor.id, instance.resource.id, null, sectionId, fieldId, null);
        }
    }

   //"Continue Reviewing?" post submission dialog
   if(submitResponse){//submit response
        const dialogTitle = "Continue reviewing tickets?"
        // const {message, stage_id, stage_name} = submitResponse
        const body = (
            <Stack spacing={2}>
                {
                    submitResponse.stage_id &&
                    <Button onClick={() => navigate(`/${REVIEW}/access_type/${accessTypeId}/accessor/${instance.accessor.id}/resource/${instance.resource.id}/ticket/${ticket.id}/stage/${submitResponse.stage_id}`)}>
                        {`Review again for stage: ${submitResponse.stage_name}`}
                    </Button>
                }
                {/* <Button onClick={onNextTicketClick}>
                    Next Ticket
                </Button> */}
                <Button onClick={() => navigate(`/${TASKS}/${PENDING_TASKS}`)}>
                    Back to pending tasks
                </Button>
            </Stack>
        )
        return(
            <ConfirmActionDialog
                title={dialogTitle}
                body={body}
                cancelText={"Close"}
                handleCancel={() => {setSubmitResponse(null)}}
                loading={false}
                disabled={false}
                instanceName={'this access'}//TODO: use a meaningful label for the name "Joes access to MySoftware"
                />
        )        
    }
    const pendingEditSummary = getPendingEditSummary(staticData, structure, instance, ticket);

    return(
        <Box>
            {/* <Stack direction={"row"}> */}
            {
                (editable_sections.length === 0) ? null :
                <Box marginBottom={1} paddingY={1}>
                    <Typography variant='h5'>You can edit the following sections:</Typography>
                    <Typography variant='h6' marginLeft={2}>{editableSectionLabels.join(", ")}</Typography>
                </Box>
            }
            <CollapseableContent isExpandedByDefault={false} title={"Past reviews of this ticket"}>
                <ActionsPanel actions={ticket.actions}/>
            </CollapseableContent>

            <CollapseableContent isExpandedByDefault={true} title={"Ticket being reviewed"}>
                {
                    !pendingEditSummary ? null : 
                    <LabelValueDisplay label={'Summary'} value={pendingEditSummary}/> 
                }
                <TicketView
                    ticket={ticket}
                    structure={structure}
                    allUsersMap={all_users}
                    editableSections={editable_sections}
                    // getFieldSetter={getSectionFieldSetter}
                    setFormValues={(x) => setTicket({...ticket, form_values: x})}
                    getSectionDownloadFileField={currTicketDownloadFileGetter}
                    />
            </CollapseableContent>

            {
                !instance.is_active ? null :
                <CollapseableContent isExpandedByDefault={true} title={"Current access details"}>
                    <MultiSectionForm
                        formData={instance.state.form_values}
                        structure={structure}
                        getSectionDownloadFileField={accessStateDownloadFileGetter}
                        />
                </CollapseableContent>
            }
            <AccessRelatedEntitiesStates accessInstance={instance} accessType={accessType}/>
            {
                (submitState === REQUEST_STATES.SUCCEEDED) ? null :
                <ResponseDialog
                    confirm_msgs={confirmation_messages}
                    submitState={submitState}
                    deferChoices={undefined}
                    onConfirmClick={onConfirmClick}
                    msgPlaceholderText={msgPlaceholderText}
                    confirmTitle={stage.stage_name}
                    canReject={canReject}
                    stage={stageConfig}
                    />
            }
            <Box marginBottom={20}/> {/* margin so the response dialog doesnt block view*/}

        </Box>

    )
}

export default ApproverAccessTicketReviewDirect