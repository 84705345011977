import React, { useState } from "react";
import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import Context from "../../Store";
import { useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import PageTitle from "../generic/PageTitle";
import TicketSelectField from "../inputs/TicketSelectField";
import { getActiveEmpChoices } from "../../helpers/StaticDataHelper";
// import SuggestedTextField from "../inputs/SuggestedTextFIeld";
import LoadButton from "../generic/LoadButton";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import TicketTextField from "../inputs/TicketTextField";
import EditIcon from '@mui/icons-material/Edit';


const CreateTag = ({empTags, refetchEmpTags}) => {
    const {alertSuccess, alertError} = React.useContext(Context);
    const [tagLabel, setTagLabel] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onCreateClick = () => {
        const onSuccess = () => {
            alertSuccess('Tag Created');
            setIsSubmitting(false);
            refetchEmpTags()
        }
        const onFailure = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }

        post(EXTENSIONS.CREATE_NEW_TAG, {tag_label: tagLabel}, onSuccess, onFailure)
        setIsSubmitting(true);
    }
    return (
        <Stack direction='row' spacing={2} sx={{...SX_BOX_SIMPLE, p:2}} maxWidth={500} marginX='auto'>
            <TicketTextField
                label='New Tag'
                value={tagLabel}
                setValue={setTagLabel}
                fullWidth
                />
            <Box alignContent='center'>
                <LoadButton onClick={onCreateClick} disabled={isSubmitting} loading={isSubmitting}>
                    Create
                </LoadButton>
            </Box>
        </Stack>
    )
}

const SingleTagView = ({tagId, tagLabel, userIdsWithTag, refetchEmpTags}) => {
    const {staticData, alertSuccess, alertError} = React.useContext(Context);
    const {all_users} = staticData;

    const [editedTagLabel, setEditedTagLabel] = useState(tagLabel)
    const [isInEditMode, setIsInEditMode] = useState(false)
    const [userId, setUserId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onDeleteClick = (tagId, userId) => {
        const body = {employee_id: userId, tag_id: tagId};
        const onSuccess = (resp) => {
            alertSuccess('Removed');
            refetchEmpTags();
        }
            
        const onFailure = (e) => {
            alertError(e.response.data);
        }
        
        post(EXTENSIONS.REMOVE_EMP_TAG, body, onSuccess, onFailure);        
    }

    const onAssignClick = () => {
        if(!userId){
            return alertError('must select a user.');
        }

        if(userIdsWithTag.includes(userId)){
            return alertError('The user already has this tag');
        }

        const body = {employee_id: userId, tag_id: tagId};
        const onSuccess = (resp) => {
            alertSuccess('Tag Assigned');
            refetchEmpTags();
            setIsSubmitting(false);
        }
        
        const onFailure = (e) => {
            alertError(e.response.data);
            setIsSubmitting(false);
        }
        
        setIsSubmitting(true);
        post(EXTENSIONS.ADD_EMP_TAG, body, onSuccess, onFailure);
    }

    const onSaveTagLabelEditClick = () => {
        if(editedTagLabel === tagLabel){
            return alertError("No change detected");
        }
        if(editedTagLabel === ''){
            return alertError("Must be non-empty text");
        }

        const body = {tag_id: tagId, tag_label: editedTagLabel};
        const onSuccess = (resp) => {
            alertSuccess('Tag Edited');
            setIsSubmitting(false);
            refetchEmpTags();
        }
        
        const onFailure = (e) => {
            alertError(e.response.data);
            setIsSubmitting(false);
        }
        
        setIsSubmitting(true);
        post(EXTENSIONS.EDIT_TAG, body, onSuccess, onFailure);        
    }

    return (
        <Box key={tagId} sx={SX_BOX_SIMPLE} maxWidth={800}>
            <Stack direction='row' alignItems='center'>
                <Tooltip title='Edit tag label'>
                    <IconButton onClick={() => setIsInEditMode(!isInEditMode)}>
                        <EditIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                {
                    isInEditMode ?
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <TicketTextField
                            label='New tag label'
                            value={editedTagLabel}
                            setValue={setEditedTagLabel}
                            />
                            <Button variant='contained' disabled={isSubmitting} onClick={onSaveTagLabelEditClick}>
                                Save
                            </Button> 
                    </Stack>
                            :
                    <Typography variant='h5'>{tagLabel}</Typography>
                }
            </Stack>
            <Stack spacing={0} marginLeft={4}>
                {
                    userIdsWithTag.map(userId => {
                        const usersName = all_users[userId];
                        return (
                            <Stack key={userId} direction='row' spacing={0}>
                                <IconButton onClick={() => onDeleteClick(tagId, userId)}>
                                    <DeleteIcon color='error'/>
                                </IconButton>
                                <Typography alignContent='center'>{usersName}</Typography>
                            </Stack>
                        )
                    })
                }
                <Stack direction='row' alignItems='center' spacing={1} marginTop={1} maxWidth={600}>
                    <TicketSelectField
                        label={`Assign ${tagLabel} to a user`}
                        value={userId}
                        setValue={setUserId}
                        choices={getActiveEmpChoices(staticData)}
                        fullWidth={true}
                        />
                    <Button variant='contained' disabled={isSubmitting} onClick={onAssignClick}>
                        Assign
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
 
}

const TagsView = ({empTags, refetchEmpTags}) => {
    const {staticData, alertSuccess, alertError} = React.useContext(Context);
    const {all_users} = staticData;

    const allTagIds = Object.keys(empTags).sort((a, b) => empTags[a].label.localeCompare(empTags[b].label)).map(tagId => Number(tagId));

    return (
        <Stack>
            {
                allTagIds.map(tagId => {
                    const tagLabel = empTags[tagId].label;
                    const userIdsWithTag = empTags[tagId].emp_ids.sort((a, b) => `${all_users[a]}`.localeCompare(`${all_users[b]}`));

                    return <SingleTagView key={tagId} tagId={tagId} tagLabel={tagLabel} userIdsWithTag={userIdsWithTag} refetchEmpTags={refetchEmpTags}/>
                })
            }
        </Stack>
    )
}


const TagsPage = () => {
    const [empTags, refetchEmpTags] = useRefetchData(EXTENSIONS.GET_EMP_TAGS, {});

    if(empTags === false) return <div>Failed to load...</div>;
    else if(empTags === null) return <LoadingMessage/>;

    return (
        <Box>
            <PageTitle title={"Manage Tags"}/>
            <CreateTag refetchEmpTags={refetchEmpTags}/>
            <Box marginY={2}/>
            <TagsView empTags={empTags} refetchEmpTags={refetchEmpTags}/>
        </Box>
    )
}

export default TagsPage;