import { Stack, Typography } from "@mui/material";


const LabelValueDisplay = ({label, value, fontSize, onLabelClick, onValueClick}) => {
    if(fontSize == undefined) fontSize = 18
    return (
        <Stack direction={'row'} spacing={1}>
            <Typography variant="subtitle2" fontSize={fontSize} alignContent={'center'} onClick={onLabelClick} sx={{cursor: onLabelClick ? 'pointer' : ''}}>
                {label}:
            </Typography>
            <Typography variant="subtitle1" fontSize={fontSize} alignContent={'center'} onClick={onValueClick} sx={{cursor: onValueClick ? 'pointer' : ''}}>
                {value}
            </Typography>
        </Stack>
    )
}

export default LabelValueDisplay;