import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Context from "../../Store";
import PageTitle from "../generic/PageTitle"
import { Box, Button, CircularProgress, Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import TicketSelectField from "../inputs/TicketSelectField";
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TicketField from "../inputs/TicketField";
import { getDefaultValue } from "../../helpers/DefaultFormValueMaker";
import { labelFromValueAndChoices, objToChoicesFlat } from "../../helpers/CommonUtil";
import { ACCESSOR_TYPES, REQUEST_STATES } from "../../helpers/Constants";
import { CONTEXT_DEPENDENCIES, formatFormValues, isFieldRelevent, makeNestedDeepCopy, makeTicketDeepCopy, sortChoicesByLabel } from "../../helpers/TicketFormHelper";
import { validateForm } from "../../helpers/TicketFormValidationHelper";
import LoadButton from "../generic/LoadButton";
import { ACCESS_REQUEST_TYPES, getAccessTypeChoices, isAccessTypeForEmp, setAllAccessLevelChoices } from "../../helpers/access_helper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingMessage from "../generic/LoadingMessage";
import { ALL_PERMS } from "../permissions/perm_constants";
import { hasGobalPermission, hasInstancePermissions } from "../permissions/perm_helper";
import { multiLineStringToJSX } from "../../helpers/FieldDisplayFormatters";
import ErrorIcon from '@mui/icons-material/Error';
import { useSearchParams } from "../../../node_modules/react-router-dom/dist/index";
import CloseableMessage from "../generic/CloseableMessage";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";

// import RefreshIcon from '@mui/icons-material/Refresh';

let nextKey = 1;
const ALERT_TIME = 6000;

const ErrorsIcon = ({report, rowKey}) => {
    if(!report) return null;
    if(!report.errors[rowKey]) return null;

    const msg = report.errors[rowKey].join("\n")

    return (
        <Box marginY='auto' marginRight={1}>
            <Tooltip title={<Typography fontSize={16}>{multiLineStringToJSX(msg)}</Typography>}>
                <ErrorIcon color={'error'} fontSize='medium'/>
            </Tooltip>
        </Box>
    )
}

const MyAddIconButton = ({onClick, disabled, tooltip}) => {
    const color = disabled ? "#cccccc" : "primary";
    return (
        <Box alignItems={"center"} display={"flex"} spacing={2} m={2}>
            <Tooltip title={tooltip}>
                <IconButton onClick={onClick} disabled={disabled}>
                    <AddCircleIcon fontSize="small" color={color}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

const MyRemoveIconButton = ({onClick, tooltip}) => {
    return (
        <Box alignItems={"center"} display={"flex"} spacing={2} m={2}>
            <Tooltip title={tooltip}>
                <IconButton onClick={onClick}>
                    <RemoveCircleIcon fontSize="small" color={"error"}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

const AccessRow = ({accessType, resourceId, value, setValue, accessorChoices, removeRow, otherRows, selectedAccessorIds, report}) => {
    const {alertError} = useContext(Context);
    const {accessor, ticket, key} = value;

    const selectedResourceInstance = null;//todo: pass as prop or infer from props
    // const sectionKeys = Object.keys(structure);
    const setFieldValue = (sectionKey, fieldKey, x) =>{
        const newValue = {...value};
        newValue.ticket[sectionKey][fieldKey] = x;
        setValue(newValue);
    }
    const onAccessorSelected = (oldValue, newValue) => {
        return;
        // // if(oldValue) return;
        // if(accessType.access_type !== ACCESSOR_TYPES.EMPLOYEE) return;
        // const username = newValue;
        // ticket.info.username = username;

        // structure.approvals.manager.choices = managers_per_emp_choices[newValue];
        // update();
    }

    const setAccessor = (newAccessor) => {
        if(selectedAccessorIds.includes(newAccessor)){
            alertError("This accessor is already selected for this resource", ALERT_TIME)
            return;
        }
        // onAccessorSelected(value.accessor, newAccessor);
        const newValue = {...value};
        newValue.accessor = newAccessor;
        setValue(newValue);
    }

    const contextValues = {
        [CONTEXT_DEPENDENCIES.EMP_ACCESSOR] : accessor,
        [CONTEXT_DEPENDENCIES.RESOURCE_ID] : resourceId,
    }
    return (
        <Stack direction={"row"} marginY={1}>
            <MyRemoveIconButton onClick={removeRow} border={2} borderRadius={2} borderColor={"#000000"} tooltip={'remove this row'}/>
            <ErrorsIcon report={report} rowKey={key}/>
            <TicketSelectField //select resource
                label={"Accessor"}
                value={accessor}
                setValue={setAccessor}
                choices={accessorChoices}
                fullWidth
                // hideValues={is_accessor_member ? undefined : otherRows.map(r => r.accessor)}
                hasBeenVisited={true}
                />
            {
                accessType.structure.sections.map(sectionStructure => {
                    const sectionKey = sectionStructure.id;
                    const sectionValue = ticket[sectionKey];
                    // const fieldKeys = Object.keys(structure[sectionKey]);

                    return sectionStructure.fields.map(fieldStructure => {
                        const fieldKey = fieldStructure.id;
                        const fieldValue = sectionValue[fieldKey];

                        if([null, undefined, ""].includes(fieldValue)){
                            const is_relevent = isFieldRelevent(fieldStructure, sectionValue, fieldValue)
                            if(!is_relevent){
                                return null;
                            }
                        }                        
                        return (
                            <TicketField
                                value={sectionValue[fieldKey]}
                                setValue={(x) => setFieldValue(sectionKey, fieldKey, x)}
                                key={sectionKey + "-" + fieldKey}
                                idPrefix={sectionKey}
                                component={fieldStructure.component}
                                valueType={fieldStructure.valueType}
                                choices={fieldStructure.choices}
                                label={fieldStructure.label}
                                helperText={fieldStructure.helperText}
                                sectionKey={sectionKey}
                                fieldKey={fieldKey}
                                dependencies={fieldStructure.dependencies}
                                sectionValues={sectionValue}
                                hasBeenVisited={true}
                                optional={fieldStructure.optional}
                                contextValues={contextValues}
                                fullWidth
                                fieldStructure={fieldStructure}
                                />
                        )
                    })
                })
            }
        </Stack>
    )
}

const AccessResourceSection = ({accessType, value, setValue, removeSection, isExpanded, toggleExpanded, selectedResources, resourceChoices, report}) => {
    //resourceChoices has valid accessor choices for each resource choice
    const {staticData, alertError} = React.useContext(Context);
    const {resource, rows} = value;
    // const [accessorChoices, resourceChoices] = getAccessorAndResourceChoices(staticData, accessType)

    if(!isExpanded){
        const resourceName = labelFromValueAndChoices(value.resource, resourceChoices, "Invalid Resource Selected");
        return (
            <Box borderColor={"#000000"} borderRadius={1} border={1} padding={1} marginY={1} alignItems={"center"} display={"flex"}>
                <Stack direction='row' alignItems={"center"} display={"flex"}>
                    <IconButton onClick={toggleExpanded}>
                        <ExpandMoreIcon/>
                    </IconButton>
                    <Typography>{resourceName} ({value.rows.length} accessor rows)</Typography>
                </Stack>
            </Box>
        )
    }

    const setResource = (x) => {
        if(selectedResources.includes(x)){
            alertError("Another section is already selected for this resource.", ALERT_TIME)
            return;
        }
        setValue({...value, resource: x});
    }

    const addRow = () => {
        if(!value.resource){
            alertError("select the resource being accessed before adding accessors.", ALERT_TIME);
            return;
        }
        else if((rows.length > 0) && (!rows[rows.length - 1].accessor)){
            alertError("select the accessor for the last row before adding more accessors", ALERT_TIME);
            return;
        }
        const newValue = {...value};
        newValue.rows.push(makeEmptyRow(accessType.structure));
        setValue(newValue);
    }
    
    const removeRow = (i) => {
        const newValue = {...value};
        newValue.rows.splice(i, 1);
        setValue(newValue)
    }
    
    const setRow = (idx, newRowValue) => {
        const newValue = {...value};
        newValue.rows[idx] = newRowValue;
        setValue(newValue)
    }

    const selectedAccessorIds = value.rows.map(row => row.accessor);
    const accessorChoices = resource ? resourceChoices.find(choice => choice.value === resource).accessorChoices : []
    return (
        <Box borderColor={"#000000"} borderRadius={1} border={1} padding={3} marginY={1}>
            <Stack direction={"row"}>
                <MyRemoveIconButton onClick={removeSection} tooltip={'remove this resource section and all its rows'}/>
                <TicketSelectField //select resource
                    label={accessType.resource_type.name}
                    value={resource}
                    setValue={setResource}
                    choices={resourceChoices}
                    fullWidth
                    // hideValues={allSelected}
                    hasBeenVisited={true}
                    />
            </Stack>
            <Box marginLeft={4}>
                {
                    rows.map((row, rowIdx) => {
                        return(
                            <AccessRow
                                key={row.key}
                                accessType={accessType}
                                resourceId={resource}
                                accessorChoices={accessorChoices}
                                value={row}
                                setValue={(x) => setRow(rowIdx, x)}
                                removeRow={() => removeRow(rowIdx)}
                                otherRows={rows}
                                selectedAccessorIds={selectedAccessorIds}
                                report={report}
                                />
                        )})
                }
                <Stack direction='row'>
                    <MyAddIconButton onClick={addRow} tooltip={'Add an accessor for this resource'}/>
                    <Typography alignItems={"center"} display={"flex"}>
                        {value.rows.length} accessor row(s) provided
                    </Typography>
                </Stack>
            </Box>
        </Box>
    )
}

const makeEmptyRow = (structure) => {
    const ticket = getDefaultValue(structure);
    return {
        accessor: "",
        ticket: ticket,
        key: nextKey++
    }
}

const formatDataForSubmission = (accessType, datas) => {
    const output = []
    for(const resourceSection of datas){
        const {resource, rows} = resourceSection;
        for(const row of rows){
            const {accessor, ticket, key} = row;
            const entry = {
                resource_id: resource,
                accessor_id: accessor,
                form_values: formatFormValues(ticket, accessType.structure),
                key: key
            }
            output.push(entry);
        }
    }
    return output;
}

const getErrorReport = (accessType, formatedDatas) => {
    const report = {
        errors: {},
    }

    for(const data of formatedDatas){
        const {key, resource_id, accessor_id, form_values} = data;
        const entryErrors = []
        if(!resource_id) entryErrors.push('missing resource');
        if(!accessor_id) entryErrors.push('missing accessor');
        const contextValues = {[CONTEXT_DEPENDENCIES.EMP_ACCESSOR]: accessor_id, [CONTEXT_DEPENDENCIES.RESOURCE_ID]: resource_id};
        const formErrors = validateForm(form_values, accessType.structure, true, contextValues);
        entryErrors.push(...formErrors);
        if(entryErrors.length > 0){
            report.errors[key] = entryErrors;
        }
    }

    return report;
}


/**
 * @returns list of resource choices and accessor choices for each resource that the user has permission to bulk import
 */
const filterResourceAndAccessorChoices = (userData, staticData, accessType, resources, accessors) => {
    // console.log('filtering params', {userData, staticData, accessType, resources, accessors})
    const output = [];
    const isAccessorRoot = !accessType.accessor_type?.parent_type;
    const isAccessorEmp = isAccessTypeForEmp(accessType);
    for(const r of resources){
        if(!r.is_active) continue;
        const accessorChoices = []

        for(const a of accessors){
            if(!a.is_active) continue
            const dummyInstance = {
                __meta_type: 'AccessInstance',
                accessor: a,
                resource: r,
            }
            const hasPerm = hasInstancePermissions(userData, staticData, ALL_PERMS.ACCESS_ACTIONS.BULK_IMPORT.key, dummyInstance, accessType);
            if(hasPerm){
                const accessorChoice = {
                    value: a.id,
                    label: (isAccessorRoot || isAccessorEmp) ? a.name : `${a.parent.name} - ${a.name}`,
                    accessor: a
                };
                accessorChoices.push(accessorChoice);
            }
        }
        if(accessorChoices.length > 0){
            sortChoicesByLabel(accessorChoices)
            const resourceChoiceData = {
                value: r.id,
                label: r.name,
                resource: r,
                accessorChoices: accessorChoices
            }
            output.push(resourceChoiceData)
        }
    }
    sortChoicesByLabel(output)
    return output;
}

const AccessBulkImport = ({accessType, updateAccessLevels}) => {
    const {user, staticData, alertError, alertSuccess} = React.useContext(Context);
    const {access_types} = staticData;

    const [accessorsData, setAccessorsData] = useState(null);

    const resourcesData = useData(EXTENSIONS.GET_ENTITIES_OF_INSTANCE_TYPE, {instance_type_id: accessType.resource_type.id});

    React.useEffect(() => {
        if(isAccessTypeForEmp(accessType)){
            const emps = Object.keys(staticData.active_users).map(empId => {return {id: Number(empId), name: staticData.active_users[empId], is_active: true}})
            setAccessorsData(emps);
        }
        else {
            const onSuccess = (resp) => setAccessorsData(resp.data.data);
            const onFailure = (e) => setAccessorsData(false);
            post(EXTENSIONS.GET_ENTITIES_OF_INSTANCE_TYPE, {instance_type_id: accessType.accessor_type.id}, onSuccess, onFailure)
        }

    }, [accessType.accessor_type?.id]);

    const [accessData, setAccessData] = useState([]);
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);
    const [expandedResourceSection, setExpandedResourceSection] = useState(null);
    const [errorReport, setErrorReport] = useState(null);


    const awaitedDatas = [accessorsData, resourcesData];
    if(awaitedDatas.includes(false)) return <div>Failed to load</div>;
    else if(awaitedDatas.includes(null)) return <LoadingMessage/>
    
    const resourceChoices = filterResourceAndAccessorChoices(user, staticData, accessType, resourcesData.data, accessorsData)

    const isFetchingLevels = false; //todo: update
    const toggleExpandedResourceSection = (idx) => {
        if(idx === expandedResourceSection) setExpandedResourceSection(null);
        else setExpandedResourceSection(idx);
    }
    const update = () => {
        setAccessData([...accessData]);
    }

    const removeSection = (i) => {
        accessData.splice(i, 1);
        update();
        if(expandedResourceSection === i) setExpandedResourceSection(null);
    }


    const addResourceSection = () => {
        const canAddResourceSection = ((accessData.length === 0) || (accessData[accessData.length - 1].resource));
        if(!canAddResourceSection){
            alertError('Must select a resource for previous section first');
            return
        }
        accessData.push({resource: "", rows: [], key: nextKey++});
        update();
        setExpandedResourceSection(accessData.length - 1)
    }

    const onSubmitClick = () => {
        setErrorReport(null);
        const dataCopy = makeNestedDeepCopy(accessData);
        const formatedData = formatDataForSubmission(accessType, dataCopy);
        const report = getErrorReport(accessType, formatedData);
        // console.log('pre submit', {formatedData, report, len: report.errors.length})
        const hasErrors = Object.keys(report.errors).length > 0;
        if(hasErrors){
            alertError("Please fix errors", ALERT_TIME);
            setErrorReport(report);
            return;
        }
        

        const onSuccess = (resp) => {
            alertSuccess("Successfully imported data");
            setErrorReport(null);
            setSubmitState(REQUEST_STATES.SUCCEEDED);
        }
        const onFail = (e) => {
            setSubmitState(REQUEST_STATES.FAILED);
            alertError("Failed to import");
            if(e.response.status !== 500){
                // const importErrorsStr = e.response.data;
                console.log('Setting error report', e);
                setErrorReport(e.response.data)
            }
        }
        const body = {
            access_type_id: accessType.id,
            data: formatedData
        }
        post(EXTENSIONS.BULK_IMPORT_ACCESS_INFO, body, onSuccess, onFail);
        setSubmitState(REQUEST_STATES.SENDING);
    }

    const setResourceSectionValue = (idx, newResourceSections) => {
        //need to impl and update prop reference to curry the param
        const newData = [...accessData];
        newData[idx] = newResourceSections;
        setAccessData(newData)
    }

    const selectedResourceIds = accessData.map(resourceSection => resourceSection.resource)
    const hasGlobalBulkImportPermission = hasGobalPermission(user, ALL_PERMS.ACCESS_ACTIONS.BULK_IMPORT.key);
    return (
        <Box>
            <PageTitle title={"Bulk Import Access"}/>
            {
                hasGlobalBulkImportPermission ? null :
                <CloseableMessage message={"Note: you do not have global permission to bulk import access, so some accessors or resources may not appear in the dropdown menus."}/>
            }
            <Box>
                {
                    accessData.map((resourceSection, idx) => {
                        return (
                            <AccessResourceSection
                                accessType={accessType}
                                value={resourceSection}
                                setValue={(x) => setResourceSectionValue(idx, x)}
                                removeSection={() => removeSection(idx)}
                                key={resourceSection.key}
                                isExpanded={idx===expandedResourceSection}
                                toggleExpanded={() => toggleExpandedResourceSection(idx)}
                                selectedResources={selectedResourceIds}
                                resourceChoices={resourceChoices}
                                report={errorReport}
                                />
                        )
                    })
                }
                <MyAddIconButton onClick={addResourceSection} tooltip={'Add another resource and accessors for it.'}/>
                <Box justifyContent={"center"} alignItems={"center"} display={"flex"} spacing={2} m={2}>
                    <LoadButton
                        onClick={onSubmitClick}
                        variant="contained"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={isFetchingLevels || [REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        >
                        Submit
                    </LoadButton>
                    <LoadButton
                        onClick={updateAccessLevels}
                        variant="contained"
                        loading={isFetchingLevels}
                        disabled={isFetchingLevels}
                        tooltip={"Resync Access Level Choices"}
                        >
                            sync
                    </LoadButton>
                </Box>
                {/* {
                    errorMsg &&
                    <Box>
                        <Typography>Errors:</Typography>
                        {
                            errorMsg.split("\n").map((s, idx) =>{
                                return(
                                    <Fragment key={idx}>
                                        {s}
                                        <br/>
                                    </Fragment>
                                    )
                            })
                        }
                    </Box>
                } */}
                
            </Box>
        </Box>
    )
}

const AccessBulkImportPage = () => {
    const { alertError, staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const [searchParams, setSearchParams] = useSearchParams();
    // const [accessTypeId, setAccessTypeId] = useState('');

    const accessTypeIdParam = searchParams.get('access_type')
    const accessTypeId = accessTypeIdParam ? Number(accessTypeIdParam) : ''
    // console.log('params', a)

    const fetchAndSetAccessLevels = (shouldNullify) => {
        if(!configSyncState) return;

        const onSucceeded = (response) => {
            setAllAccessLevelChoices(staticData.access_types, response.data);
        }
        const onFailed = (err) => {
            alertError("Failed to fetch access levels");
        }
        post(EXTENSIONS.GET_ACCESS_LEVELS_PER_RESOURCE, {}, onSucceeded, onFailed)
    }

    useEffect(() => {
        fetchAndSetAccessLevels(true);
    }, [configSyncState])

    if(configSyncState === false) return <FailedToLoadMessage/>;
    if(configSyncState === null) return <LoadingMessage/>

    if(!accessTypeId){
        return (
            <TicketSelectField
                label="Access Type"
                value={accessTypeId || ''}
                setValue={(x) => setSearchParams({access_type: x})}
                choices={getAccessTypeChoices(staticData.access_types)}
                fullWidth
                />
        )
    }

    const accessType = staticData.access_types.find(at => at.id === accessTypeId);
    if(!accessType) return <div>Page not found (invalid access type id)</div>
    return(
        <AccessBulkImport
            // allEntityNames={allEntityNames}
            accessType={accessType}
            // allAccessLevels={allAccessLevels}
            updateAccessLevels={() => fetchAndSetAccessLevels(false)}
            // isFetchingLevels={isFetchingLevels}
            />
    )

}

export default AccessBulkImportPage;