import { ACCESS_REQUEST, VIEW } from "../App";
import { ADMIN_NEW, HRIS, MANAGED_TABLES } from "../components/app/LayeredNavPanel";
import { getEntityTypeWithInstanceTypeId } from "./StaticDataHelper";



// export const makeURLToEmployeeId = (employeeId) => {

// }

export const makeURLToEmployeeObject = (employeeObject) => {
    return `/${HRIS}?view=Employee&emp_id=${employeeObject.id}`
}

export const makeURLToEntityId = (staticData, entityInstanceTypeId, entityId) => {
    const entityType = getEntityTypeWithInstanceTypeId(staticData, entityInstanceTypeId);
    const entityTypeId = entityType.id;
    return `/${VIEW}/entity_type/${entityTypeId}/eti/${entityId}`;
}
export const makeURLToEntityObject = (staticData, entityInstanceType, entity) => {
    return makeURLToEntityId(staticData, entityInstanceType.id, entity.id);
    // const entityType = getEntityTypeWithInstanceTypeId(staticData, entityInstanceType.id);
    // const entityTypeId = entityType.id;
    // return `/${VIEW}/entity_type/${entityTypeId}/eti/${entity.id}`;
}

export const makeURLToAccessObject = (accessType, access) => {
    const accessorId = access.accessor.id;
    const resourceId = access.resource.id;
    return `/${ACCESS_REQUEST}/access_type/${accessType.id}/accessor/${accessorId}/resource/${resourceId}`;
}

export const makeURLToTableId = (tableId) => {
    return `/${ADMIN_NEW}/${MANAGED_TABLES}?view=table&table_id=${tableId}`
}