import { ACCESS_REQUEST_TYPES, ENTITY_REQUEST_TYPES, FALLBACK_FIELD_LABEL, FALLBACK_REVIEWER_ID, FALLBACK_REVIEWER_NAME, REVIEWER_ACTION_TYPES, STAGE_TYPE_METAS, TICKET_STATUS } from "./Constants";
import { datetimeDisplay, fieldValueString } from "./FieldDisplayFormatters";
import { getFormValuesWithReviewer, getReviewerIdsOfStage } from "./process_helper";
import { getEmployeeNameWithId, getEntityOrUserName, getFieldStructureFromFormStructureWithIds, getStageWithIdFromInstanceType } from "./StaticDataHelper";

export const DEFAULT_INITIAL_ROWS_PER_PAGE = 25;
export const getDefaultTableRowsPerPageOptions = (rows) => {
    const numRows = rows.length;
    const output = [5, 10, 25, 50, 100];
    if(!output.includes(numRows)) output.push(numRows);
    return output;
}

export const getCustomColumnNames = (entityInstanceType, columnDisplays) => {
    const structure = entityInstanceType.structure;
    const columnNames = columnDisplays.map(d => {
        const {section, field} = d;
        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, section, field);
        return fieldStructure ? fieldStructure.short_label : FALLBACK_FIELD_LABEL;
    });

    return columnNames;
}

export const renderCustomRowCells = (entityInstanceType, table_column_displays, staticData, form_values) => {
    const columnDisplays = table_column_displays;
    const structure = entityInstanceType.structure;

    const cells = columnDisplays.map(d => {
        const {section, field} = d;
        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, section, field);
        if(!fieldStructure || !form_values?.[d.section]?.[d.field]) return "";
        return fieldValueString(form_values[d.section][d.field], fieldStructure, staticData)
    })

    return cells;
}


export const getReviewerNamesOfStages = (ticket, staticData, instanceType) => {
    const reviewerIds = new Set();

    for(const stageData of ticket.stages){
        const stageId = stageData.stage_id;
        const stageConfig = getStageWithIdFromInstanceType(instanceType, stageId);
        if(!stageConfig){
            continue;
        }
    }

    const names = []
    for(const userId of reviewerIds){
        const name = getEmployeeNameWithId(staticData, userId);
        names.push(name)
    }

    const output = names.join(", ");
    return output;

}

