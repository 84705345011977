import React, { useState} from 'react';
import { EXTENSIONS, post } from "../../helpers/requests";
import PageTitle from "../generic/PageTitle";
import Context from "../../Store";

import LoadingMessage from '../generic/LoadingMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { VIEW } from '../../App';
import { useData, useEnsureUpToDateConfigs, useTenantNavigate } from '../../helpers/CustomHooks';
import { Button } from '@mui/material';
import { getEntityName, getEntityOrUserName, sortEntitiesByParentThenOwnName, sortEntitiesOfTypeByName } from '../../helpers/StaticDataHelper';
import AbstractTable from '../tables/AbstractTable';
import { exportEntitiesToCsv } from '../../helpers/csv_downloader';
import { FailedToLoadMessage } from '../generic/FailedToLoadMessage';

/*
todo:
X - conditionally call EXTENSIONS.get_all_entity_instances_of_instance_type
- conditionally display parent column
- conditionally sort/group by parent name
*/
const ALL_PARENTS_PARAM = 'all'

const AdminViewAllPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();
    const {entity_types, all_users} = staticData;
    const navigate = useTenantNavigate();

    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);
    const parentIdStr = params.entity_type_instance_id;
    const isAllMembers = parentIdStr === ALL_PARENTS_PARAM;
    const parent_id = (parentIdStr && (!isAllMembers)) ? Number(parentIdStr) : null;
    
    const isMembers = (!!parent_id) || isAllMembers;
    const parentName = getEntityName(staticData, parent_id);
    const entityType = entity_types.find(et => et.id === entity_type_id);
    //TODO: this isnt good that we need the instance type id for the request body, and depend on the cached static data for it. fix.
    const entityInstanceType = isMembers ? entityType.member_instance_type : entityType.root_instance_type; 
    
    const onDataFetched = (resp) => sortEntitiesByParentThenOwnName(resp.data.data)
    const ext = isAllMembers ? EXTENSIONS.GET_ENTITIES_OF_INSTANCE_TYPE : EXTENSIONS.GET_ALL_ENTITIES;
    const data = useData(ext, {entity_type_id, parent_id, instance_type_id: entityInstanceType.id}, onDataFetched);
    
    const awaitedDatas = [configSyncState, data]
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;
    const entities = data.data;


    const title = `All ${entityInstanceType.name}s`;

    const columnNames = [
        isAllMembers ? 'Parent' : '',
        entityInstanceType.name, 'Active', 'Pending Request Types'
    ]
    const renderRowCells = (entity) => {
        const pendingRequestTypes = entity.tickets.reduce((acc, curr) => {
            if(curr.status === "Pending" && !acc.includes(curr.request_type)) acc.push(curr.request_type);
            return acc;
        }, [])

        const pendingRequestTypesLabel = pendingRequestTypes.length ? pendingRequestTypes.join(", ") : "-"
        return [
            isAllMembers ? entity.parent.name : '',
            getEntityOrUserName(entity, entityInstanceType, all_users),
            entity.is_active ? "Yes" : "No",
            pendingRequestTypesLabel
        ]
    }

    const onRowClick = (entity) => {
        const urlExt = `/${VIEW}/entity_type/${entity_type_id}/eti/${entity.id}`
        navigate(urlExt);
    }

    const exportCSV = () => {
        const filename = `all_${entityInstanceType.name}s`
        exportEntitiesToCsv(filename, entityInstanceType, entities, staticData);
    }
    return (
        <div key={`ApprovedPage-${entity_type_id}--${parentIdStr}`}>
            <PageTitle title={title} subtitle={parentName}/>
            <Button onClick={exportCSV} variant='contained'>
                Export
            </Button>
            <AbstractTable
                rows={entities}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowToKey={e => e.id}
                rowHeight={"small"}
                onRowClick={onRowClick}
                />
            {/* <ApprovedEntitiesTable
                structure={structure}
                entities={entities}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                /> */}
        </div>
    )
    /***********************************/
}

export default AdminViewAllPage;
