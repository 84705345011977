import {AppBar, Stack, Toolbar, Typography} from '@mui/material';
import axotrax_logo from '../../axotrax_logo.png'
import React, { useState } from 'react';
import { Box } from '../../../node_modules/@mui/material/index';
import { BULLET_CHAR, TENANT_URL_PATH_PRECEDOR } from '../../helpers/Constants';
import { blue_light } from '../../Colors';
import { Navigate, useNavigate } from '../../../node_modules/react-router-dom/dist/index';

export const NoTenantProvidedPage = () => {
    const navigate = useNavigate();

    const currUrlExtension = window.location.pathname + window.location.search;
    const correctedExtension = `${TENANT_URL_PATH_PRECEDOR}/mangolanguages${currUrlExtension}`;
    // console.log(`url: ${currUrlExtension}`, {loc: window.location})

    return <Navigate to={correctedExtension} replace={true}/>

    return (
        <Box>
            <BlankHeader/>
            <Toolbar/>
            <Box p={2}  bgcolor={'#ddeeff'}  sx={{width: '100vw', height:'100vh'}}>
                <Typography variant="h5">Page Not Found</Typography>
                <Box marginTop={2}>
                    <Typography variant="p" marginTop={2}>
                        No client extension provided. Possible solutions:
                    </Typography>
                    <Stack marginLeft={2}>
                        <Typography>
                            {BULLET_CHAR} search your browser history for a URL that begins with /{TENANT_URL_PATH_PRECEDOR}/your_company_extension
                        </Typography>
                        <Typography>
                            {BULLET_CHAR} Contact your administrator for a proper URL with your company's extension.
                        </Typography>
                    </Stack>
                </Box>

            </Box>
        </Box>
    )
}

const BlankHeader = () => {
    return(
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <Stack direction={"row"} alignItems={"center"} width={"33%"}>
                    <img src={axotrax_logo} height={45} alt='logo'/>
                    <Typography variant="h6" noWrap component="div" marginLeft={2}>
                        AxoTrax
                    </Typography>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}