import { ListSubheader, MenuItem, Stack, TextField, Typography} from '@mui/material';
import React, { useEffect, useMemo, useState} from 'react';
import AbstractTable from './AbstractTable';
import { VIEW } from '../../App';
import { useNavigate } from 'react-router-dom';
import { datetimeDisplay, fieldValueString } from '../../helpers/FieldDisplayFormatters';
import { DEFAULT_INITIAL_ROWS_PER_PAGE, getDefaultTableRowsPerPageOptions } from '../../helpers/TableHelper';
import Context from '../../Store';
import { getFieldStructureFromFormStructureWithIds } from '../../helpers/StaticDataHelper';
import { useTenantNavigate } from '../../helpers/CustomHooks';


const ANY_FIELD = "Any Field"
const SEARCH_FIELD_DELIM = " "//a field and section cant have a space


const FieldFilterSelect = ({structure, value, setValue}) => {
    const choices = []
    choices.push(<MenuItem key={ANY_FIELD} value={ANY_FIELD}>{ANY_FIELD}</MenuItem>  )
    
    structure.sections.forEach(sectionStructure => {
        if(sectionStructure.is_deleted) return;

        const sectionId = sectionStructure.id;
        choices.push(<ListSubheader key={`header-${sectionId}`}><Typography variant='h6'>{sectionStructure.metadata.label}</Typography></ListSubheader>)
        
        sectionStructure.fields.forEach(fieldStructure => {
            if(fieldStructure.is_deleted) return;

            const fieldId = fieldStructure.id;
            const value = sectionId + SEARCH_FIELD_DELIM + fieldId
            const label = fieldStructure.short_label;
            choices.push(<MenuItem key={value} value={value}>{label}</MenuItem> )            
        })
    })

    return(
        <TextField
            select
            label={"Filter by"}
            onChange={e => setValue(e.target.value)}
            value={value}
            fullWidth={true}
            sx={{maxWidth: "60%"}}
            >
                { choices }
        </TextField> 
    )
}

const doesFormContainSearchTerm = (structure, formValues, searchTerm, staticData) => {
    for(const section of structure.sections){
        for(const field of section.fields){
            if(!section.is_deleted && !field.is_deleted){
                const fieldValue = formValues[section.id]?.[field.id];
    
                const displayText = fieldValueString(fieldValue, field, staticData)
                if(displayText.toLowerCase().includes(searchTerm)){
                    return true
                }            
            }
        }
    }
    return false;
}
const filterEntities = (entities, searchField, term, structure, staticData) => {

    const output = []
    for(var tIdx = 0; tIdx < entities.length; tIdx++){
        const entity = entities[tIdx];
        const form_values = entity.state.form_values;

        if(searchField === ANY_FIELD){
            const name = entity.name;
            if(name.toString().toLowerCase().includes(term)){
                output.push(entity);
            }
            else{
                const found = doesFormContainSearchTerm(structure, form_values, term, staticData);
                if(found){
                    output.push(entity)
                }
            }       
        }
        else{
            const parts = searchField.split(SEARCH_FIELD_DELIM)
            const sectionKey = parts[0]
            const fieldKey = parts[1]
            const fieldValue = form_values[sectionKey]?.[fieldKey]
            const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, sectionKey, fieldKey)
            const displayText = fieldValueString(fieldValue, fieldStructure, staticData)
            if(displayText.toLowerCase().includes(term)){
                output.push(entity)
            }
        }
    }

    return output;
}

const ApprovedEntitiesTable = ({structure, entities, columnNames, renderRowCells, onRowClick}) => {
    const {staticData} = React.useContext(Context)
    const [searchField, setSearchField] = useState(ANY_FIELD)
    const [searchTerm, setSearchTerm] = useState("")

    const navigate = useTenantNavigate()
    const rowToKey = (entity) => {return entity.id}
    
    const handleSearchTermChange = (e) => {
        const newVal = e.target.value.toLowerCase()
        setSearchTerm(newVal)   
    }

    const filteredEntities = useMemo(() => {
        return (searchTerm.length > 0) ? filterEntities(entities, searchField, searchTerm, structure, staticData) : entities;
    }, [searchField, searchTerm, entities])

    // const handleRowClick = onRowClick ? onRowClick : (ticket) => navigate(`/${entity_type}/${VIEW}/${ticket.metadata.id}`)
    return (
        <>
        {/* <TextField value={searchTerm} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/> */}
        {
            (entities.length > 0 && structure) &&
            <Stack direction={"row"} marginY={2} alignContent={"center"} spacing={2}>
                <TextField value={searchTerm} onChange={handleSearchTermChange} label={"Search term"} autoComplete='off' sx={{maxWidth: "40%"}} fullWidth/>
                <FieldFilterSelect structure={structure} value={searchField} setValue={setSearchField} />
            </Stack>
        }
        <AbstractTable
            rows={filteredEntities}
            rowToKey={rowToKey}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            onRowClick={onRowClick}
            // onRowHover={onRowHover}
            rowsPerPageOptions={getDefaultTableRowsPerPageOptions(filteredEntities)}
            initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
            rowHeight={"small"}
            />
        </>
    )

}

export default ApprovedEntitiesTable;