import React, { useState } from "react";
import { COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, TICKET_STATUS } from "../../helpers/Constants";
import Context from "../../Store";
import { useParams } from "../../../node_modules/react-router-dom/dist/index";
import { getEntityName, getFieldStructureFromFormStructureWithIds } from "../../helpers/StaticDataHelper";
import { useData, useEnsureUpToDateConfigs, useTenantNavigate } from "../../helpers/CustomHooks";
import { EXTENSIONS } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import { DEFAULT_INITIAL_ROWS_PER_PAGE, getCustomColumnNames, getDefaultTableRowsPerPageOptions, getReviewerNamesOfStages, renderCustomRowCells } from "../../helpers/TableHelper";
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import { datetimeDisplay, fieldValueString } from "../../helpers/FieldDisplayFormatters";
import { VIEW } from "../../App";
import ProgressGraph from "../graph_stage_progress/ProgressGraph";
import AbstractTable from "../tables/AbstractTable";
import PageTitle from "../generic/PageTitle";
import MyTabs from "../generic/MyTabs";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";
import { AllReviewersOfTicket } from "../instance/AllReviewersOfTicket";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const separateEntities = (entities) => {
    entities.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
    const output = {
        additions: [],
        removals: [],
        edits: []
    };
    for(const e of entities){
        const tickets = e.tickets;
        const pendingTickets = tickets.filter(t => t.status === TICKET_STATUS.PENDING)
        const additionTicket = pendingTickets.find(t => [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN].includes(t.request_type));
        const removalTicket = pendingTickets.find(t => t.request_type === ENTITY_REQUEST_TYPES.REMOVE);
        const editTickets = pendingTickets.filter(t => t.request_type == COMMON_REQUEST_TYPES.EDIT_REQUEST)
        
        if(additionTicket){
            output.additions.push({entity: e, ticket: additionTicket});
        }
        if(removalTicket){
            output.removals.push({entity: e, ticket: removalTicket});
        }
        for(const t of editTickets){
            output.edits.push({entity: e, ticket: t})
        }
    }

    return output;
}

export const StagesCell = ({instance, ticket, staticData, instanceType}) => {
    const stagesNames = ticket.stages.map(s => s.stage_name).join(", ");
    const stagesReviewers = getReviewerNamesOfStages(ticket, staticData, instanceType)

    return (
        <div>
            <Tooltip title={stagesReviewers}>
                <Typography variant='h6'>
                    {stagesNames}
                </Typography>
            </Tooltip>
        </div>
    )
}

const getSubmitterAndTimeTexts = (ticket, staticData) => {
    const submissionAction = ticket.actions.find(a => a.role === "Submitter")
    if(submissionAction){
        const submitterName = submissionAction.actor_id ? staticData.all_users[submissionAction.actor_id] : "System";
        const submissionTime = datetimeDisplay(submissionAction.datetime, true)
        return [submitterName, submissionTime]
    }
    else return ['-', '-']
}

export const RowExpansionCell = ({onExpandClick, isExpanded}) => {
    return (
        <IconButton onClick={onExpandClick} size={"small"} sx={{ marginLeft: -1, marginRight:-10, p: 0, minWidth:0 }}>
            {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </IconButton>
    )    
}
const getCommonCells = (entity, ticket, staticData, entityInstanceType, onExpandClick, isExpanded) => {
    const [submitterName, submissionTime] = getSubmitterAndTimeTexts(ticket, staticData)
    return [
        <RowExpansionCell onExpandClick={onExpandClick} isExpanded={isExpanded}/>,
        entity.name,
        <StagesCell instance={entity} ticket={ticket} staticData={staticData} instanceType={entityInstanceType}/>,
        submitterName,
        submissionTime
    ]
}

const SectionTitle = ({title}) => {
    return null;
    // return <Typography variant='h5'>{title}</Typography>
}

export const PendingTicketRowExpansion = ({instanceType, instance, ticket}) => {
    return (
        <Box>
            <Box p={2}>
                <AllReviewersOfTicket instanceType={instanceType} instance={instance} ticket={ticket}/>
            </Box>
                <Divider sx={{ bgcolor: "#000000", borderBottomWidth: 2}}/>
        </Box>
    )
}

const AdditionsView = ({datas, entityInstanceType, onRowClick}) => {
    const {staticData} = React.useContext(Context);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);

    // const entityInstanceType = isMembers ? entityType.member_instance_type : entityType.root_instance_type;

    const columnDisplays = entityInstanceType.pending_table_display.table_column_displays;
    const customColumnNames = getCustomColumnNames(entityInstanceType, columnDisplays);
    const columnNames = ['', entityInstanceType.name, 'Stages', 'Requester', 'Sub Date', ...customColumnNames]//.splice(1, 0, )

    const onExpandClick = (event, row) => {
        event.stopPropagation();
        const newExpandedRow = (row === expandedRow) ? null : row;
        setExpandedRow(newExpandedRow)
    }
    const renderRowCells = (row) => {
        const {entity, ticket} = row
        const formValues = ticket.form_values;

        const customCells = renderCustomRowCells(entityInstanceType, columnDisplays, staticData, formValues);
        const isExpanded = (row === expandedRow)
        let commonCells = getCommonCells(entity, ticket, staticData, entityInstanceType, (e)=>onExpandClick(e, row), isExpanded);
        
        const cells = [...commonCells, ...customCells]
        // cells.splice(1, 0, ...otherCells)
        return cells;
    }

    const approvalProcess = entityInstanceType.addition_process;
    const hoveredTicketStageIds = hoveredRow ? hoveredRow.ticket.stages.map(stageInfo => stageInfo.stage_id) : [];

    const renderRowExpansion = ({entity, ticket}) => {
        return <PendingTicketRowExpansion instanceType={entityInstanceType} instance={entity} ticket={ticket}/>
    }

    return (
        <div>
            <div>
                <SectionTitle title={'Pending Additions'}/>
                <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={hoveredTicketStageIds}/>
            </div>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.ticket.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                onRowHover={(r) => setHoveredRow(r)}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(datas)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                renderRowExpansion={renderRowExpansion}
                expandedRow={expandedRow}
                />
        </div>
    )
}

const RemovalsView = ({datas, entityInstanceType, onRowClick}) => {
    const {staticData} = React.useContext(Context);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);

    const approvalProcess = entityInstanceType.removal_process;
    const hoveredTicketStageIds = hoveredRow ? hoveredRow.ticket.stages.map(stageInfo => stageInfo.stage_id) : [];
    
    const columnNames = ['', entityInstanceType.name, 'Stages', 'Requester', 'Sub Date'];

    const onExpandClick = (event, row) => {
        event.stopPropagation();
        const newExpandedRow = (row === expandedRow) ? null : row;
        setExpandedRow(newExpandedRow)
    }

    const renderRowCells = (row) => {
        const {entity, ticket} = row;
        const isExpanded = (row === expandedRow)

        let cells = getCommonCells(entity, ticket, staticData, entityInstanceType, (e)=>onExpandClick(e, row), isExpanded)
        // cells.unshift(entity.name);
        return cells;
    }

    const renderRowExpansion = ({entity, ticket}) => {
        return <PendingTicketRowExpansion instanceType={entityInstanceType} instance={entity} ticket={ticket}/>
    }

    return (
        <Box>
            <div>
                <SectionTitle title={'Pending Removals'}/>
                <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={hoveredTicketStageIds}/>
            </div>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.ticket.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                onRowHover={(r) => setHoveredRow(r)}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(datas)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                renderRowExpansion={renderRowExpansion}
                expandedRow={expandedRow}
                />
        </Box>
    )
}

const EditsView = ({datas, entityInstanceType, onRowClick}) => {
    const {staticData} = React.useContext(Context);
    const [expandedRow, setExpandedRow] = useState(null);
    // const [hoveredRow, setHoveredRow] = useState(null);

    // const approvalProcess = entityInstanceType.removal_process;
    // const hoveredTicketStageIds = hoveredRow ? hoveredRow.ticket.stages.map(stageInfo => stageInfo.stage_id) : [];
    const structure = entityInstanceType.structure;

    const columnNames = ['', entityInstanceType.name, 'Field', 'Current value', 'New value', 'Stages', 'Requester', 'Sub Date'];

    const onExpandClick = (event, row) => {
        // console.log('expand edit clicked', {row, expandedRow})
        event.stopPropagation();
        const newExpandedRow = (row === expandedRow) ? null : row;
        setExpandedRow(newExpandedRow)
    }

    const renderRowCells = (row) => {
        const {entity, ticket} = row;
        const sectionId = Object.keys(ticket.form_values)[0];
        const fieldId = Object.keys(ticket.form_values[sectionId])[0];

        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, sectionId, fieldId);
        const fieldLabel = fieldStructure ? fieldStructure.short_label : '';
        const currValue = entity.state.form_values[sectionId][fieldId];
        const newValue = ticket.form_values[sectionId][fieldId];

        const isExpanded = (row === expandedRow)
        const [submitterName, submissionTime] = getSubmitterAndTimeTexts(ticket, staticData)
    
        return [
            <RowExpansionCell onExpandClick={(e)=>onExpandClick(e, row)} isExpanded={isExpanded}/>,
            entity.name,
            fieldLabel,
            fieldValueString(currValue, fieldStructure, staticData),
            fieldValueString(newValue, fieldStructure, staticData),
            <StagesCell instance={entity} ticket={ticket} staticData={staticData} instanceType={entityInstanceType}/>,
            submitterName,
            submissionTime,
        ]
    }

    const renderRowExpansion = ({entity, ticket}) => {
        return <PendingTicketRowExpansion instanceType={entityInstanceType} instance={entity} ticket={ticket}/>
    }

    return (
        <div>
            <div>
                <SectionTitle title={'Pending Edits'}/>
                {/* <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={hoveredTicketStageIds}/> */}
            </div>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.ticket.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                // onRowHover={(r) => setHoveredRow(r)}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(datas)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                renderRowExpansion={renderRowExpansion}
                expandedRow={expandedRow}
                />
        </div>
    )
}

const PendingEntityTicketsPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const {entity_types} = staticData;
    const navigate = useTenantNavigate();

    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);
    const parent_id = params.entity_type_instance_id ? Number(params.entity_type_instance_id) : null;

    const data = useData(EXTENSIONS.GET_PENDING_ENTITY_TICKETS, {entity_type_id, parent_id});
    
    const awaitedDatas = [configSyncState, data]
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const isMembers = !!parent_id;
    const parentName = getEntityName(staticData, parent_id);
    const entityType = entity_types.find(et => et.id === entity_type_id);
    const entityInstanceType = isMembers ? entityType.member_instance_type : entityType.root_instance_type

    const accumulator = data;
    let entities = accumulator[entityInstanceType.id];
    entities = !isMembers ? entities : entities.filter(e => e.parent.id === parent_id);
    const { additions, removals, edits } = separateEntities(entities);

    const onRowClick = (row) => {
        const {entity, ticket} = row;
        const urlExt = `/${VIEW}/entity_type/${entity_type_id}/eti/${entity.id}`
        navigate(urlExt);
    }

    const tabDatas = [
        {
            title: `Additions (${additions.length})`,
            getContent: () => <AdditionsView datas={additions} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
        },
        {
            title: `Removals (${removals.length})`,
            getContent: () => <RemovalsView datas={removals} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
        },
        {
            title: `Edits (${edits.length})`,
            getContent: () => <EditsView datas={edits} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
        }
    ]
    return (
        <Box sx={{ width: '100%' }}>
            <PageTitle title={`${entityInstanceType.name}s: Pending Tickets`} subtitle={parentName}/>
            <MyTabs tabDatas={tabDatas} marginYToContent={2}/>
        </Box>
      );
    // return (
    //     <Box>
    //         <PageTitle title={`${entityInstanceType.name}s: Pending Tickets`} subtitle={parentName}/>
    //         <Stack spacing={2}>
    //             <AdditionsView datas={additions} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
    //             <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/>
    //             <RemovalsView datas={removals} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
    //             <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/>
    //             <EditsView datas={edits} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
    //         </Stack>
    //     </Box>
    // )
}

export default PendingEntityTicketsPage;