import { getApprovalProcess } from "../components/configs/approval_processes/ApprovalProcessBuilder";
import { getPendingActivenessTicket } from "./CommonUtil";
import { ACCESS_REQUEST_TYPES, COMMON_REQUEST_TYPES, FALLBACK_REVIEWER_ID, REVIEWER_ACTION_TYPES, STAGE_TYPE_METAS } from "./Constants";
import { getStageWithIdFromInstanceType } from "./StaticDataHelper";

const getActiveOrPendingInstanceFormValues = (instance) => {
    const pendingActivenessTicket = getPendingActivenessTicket(instance);
    const isPendingActiveness = !!pendingActivenessTicket;

    if(!instance.is_active && isPendingActiveness){
        return pendingActivenessTicket.form_values;
    }
    return instance.state.form_values;
}

export const getFormValuesWithReviewer = (fieldStage, instance, ticket) => {
    const {TICKET, STATE, PARENT, RESOURCE, ACCESSOR, ACCESSOR_PARENT} = STAGE_TYPE_METAS;
    switch (fieldStage.__meta_name) {
        case TICKET:
            return ticket.form_values;
        case STATE:
            return getActiveOrPendingInstanceFormValues(instance);
        case PARENT:
            return getActiveOrPendingInstanceFormValues(instance.parent);
        case RESOURCE:
            return getActiveOrPendingInstanceFormValues(instance.resource);
        case ACCESSOR:
            return getActiveOrPendingInstanceFormValues(instance.accessor);
        case ACCESSOR_PARENT:
            return getActiveOrPendingInstanceFormValues(instance.accessor.parent);
        default:
            console.log(`ERROR: unexpected stage meta value: ${fieldStage.__meta_name}`)
            return null;
    }
}

export const getReviewerIdsOfEnabledStages = (instance, ticket, staticData, instanceType) => {
    const {tags_map, all_users} = staticData;
    const reviewerIds = new Set();
    const unassignedTags = new Set();

    for(const stageData of ticket.stages){
        const stageId = stageData.stage_id;
        const stageConfig = getStageWithIdFromInstanceType(instanceType, stageId);
        if(!stageConfig){
            reviewerIds.add(FALLBACK_REVIEWER_ID);
        }
        else if(stageConfig.__meta_name === STAGE_TYPE_METAS.ROLE){
            const tagId = stageConfig.role;
            const stageReviewerIds = tags_map[tagId].emp_ids;
            if((stageReviewerIds === undefined) || (stageReviewerIds.length === 0)){
                unassignedTags.add(tags_map[tagId].label);
            }
            else{
                stageReviewerIds.forEach(id => reviewerIds.add(id))
            }
        }
        else{
            const formValues = getFormValuesWithReviewer(stageConfig, instance, ticket);
            const stageReviewerId = formValues[stageConfig.section_key][stageConfig.field_key];
            reviewerIds.add(stageReviewerId);
        }
    }

    const reviewerIdsArray = [...reviewerIds]
    return reviewerIdsArray;
}

export const getReviewerIdsOfStage = (instance, ticket, staticData, stageConfig) => {
    const {tags_map, all_users} = staticData;

    if(!stageConfig){
        return []
    }
    else if(stageConfig.__meta_name === STAGE_TYPE_METAS.ROLE){
        const tagId = stageConfig.role;
        const tagData = tags_map[tagId]

        if(!tagData) return [];

        const stageReviewerIds = tagData.emp_ids;
        return [...stageReviewerIds];
    }
    else{
        const formValues = getFormValuesWithReviewer(stageConfig, instance, ticket);
        const stageReviewerId = formValues[stageConfig.section_key][stageConfig.field_key];
        return [stageReviewerId]
    }
}


export const getReviewerIdsOfRemainingStages = (instance, ticket, staticData, instanceType) => {
    const output = [];

    const unreviewedStages = getUnreviwedStagesOfTicket(ticket, instanceType)
    
    for(const stageConfig of unreviewedStages){
        const stageAndReviewers = {
            stage: stageConfig,
            reviewerIds: getReviewerIdsOfStage(instance, ticket, staticData, stageConfig)
        }
        output.push(stageAndReviewers)
    }

    return output;
}

const getEditFieldId = (ticket) => {
    const editRequestTypes = [COMMON_REQUEST_TYPES.EDIT_REQUEST, ACCESS_REQUEST_TYPES.LVL_CHANGE];
    if(editRequestTypes.includes(ticket.request_type)){
        const firstSectionId = Object.keys(ticket.form_values)[0]
        const firstFieldId = Object.keys(ticket.form_values[firstSectionId])[0]
        return firstFieldId;
    }
    return null
    // case ACCESS_REQUEST_TYPES.LVL_CHANGE:
}
export const getReviewerIdsOfAllStages = (instance, ticket, staticData, instanceType) => {
    const output = [];

    const approvalProcess = getApprovalProcess(instanceType, ticket.request_type, getEditFieldId(ticket));
    if(!approvalProcess)console.log(':(', {instance, ticket, staticData, instanceType, fieldID: getEditFieldId(ticket), approvalProcess})
    const allAtomicStages = getAllAtomicStages(approvalProcess)
    
    for(const stageConfig of allAtomicStages){
        const stageAndReviewers = {
            stage: stageConfig,
            reviewerIds: getReviewerIdsOfStage(instance, ticket, staticData, stageConfig)
        }
        output.push(stageAndReviewers)
    }

    return output;
}

const getUnreviwedStagesOfTicket = (ticket, instanceType) => {
    //stage is remaining if stage is not approved
    const reviewedStageIds = ticket.actions.filter(a => a.action === REVIEWER_ACTION_TYPES.APPROVE).map(a => a.stage_id)
    const approvalProcess = getApprovalProcess(instanceType, ticket.request_type, getEditFieldId(ticket));
    const allAtomicStages = getAllAtomicStages(approvalProcess)
    const unreviewedStages = allAtomicStages.filter(stg => !reviewedStageIds.includes(stg.id))
    return unreviewedStages;
}

export const getAllAtomicStages = (approvalProcess) => {
    const output = [];

    for(let i = 0; i < approvalProcess.stages.length; i++){
        const stage = approvalProcess.stages[i];

        if(stage.__meta_name === 'ParallelApprovalsStage'){
            for(let processIdx = 0; processIdx < stage.approval_processes.length; processIdx++){
                const subProcess = stage.approval_processes[processIdx];
                const substages = getAllAtomicStages(subProcess);
                output.push(...substages)
            }
        }
        else{
            output.push(stage)
        }
    }

    return output;
}