import { Box, IconButton, Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import TicketTextField from "../../inputs/TicketTextField";
import TicketSelectField from "../../inputs/TicketSelectField";
import { ACCESS_REQUEST_TYPES, ENTITY_REQUEST_TYPES, INSTANCE_TYPE_META_NAMES, REVIEWER_ACTION_TYPES, STAGE_TYPE_METAS } from "../../../helpers/Constants";
import { FIELD_VALUE_TYPES } from "../../inputs/FieldProps";
import TicketMultiSelectField from "../../inputs/TicketMultiSelectField";
import TicketCheckbox from "../../inputs/TicketCheckbox";
import { ConfirmationMessagesField } from "./ConfirmationMessagesField";
import { hasDuplicates } from "../../../helpers/CommonUtil";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ConfigPanelHelpButton } from "./ConfigPanelHelpButton";
import SyncIcon from '@mui/icons-material/Sync';


const getStageTypeCoices = (instanceType, requestType) => {
    //TODO: take the context into consideration
    const output = [
        {value: STAGE_TYPE_METAS.ROLE, label: `Users with a certain tag`},
        {value: STAGE_TYPE_METAS.TICKET, label: `On the ticket/request`},
    ]
    
    const isAdditionRequest = [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN, ACCESS_REQUEST_TYPES.GRANT].includes(requestType)
    if(!isAdditionRequest){
        const onStateChoice = {value: STAGE_TYPE_METAS.STATE, label: `On the state`};
        output.push(onStateChoice);
    }

    const {__meta_name} = instanceType;
    const isEntityInstanceType = (__meta_name === INSTANCE_TYPE_META_NAMES.ENTITY_INSTANCE_TYPE);
    if(isEntityInstanceType && !!instanceType.parent_type){
        output.push({value: STAGE_TYPE_METAS.PARENT, label: `On the parent of this entity`})
    }
    else if(!isEntityInstanceType){
        const isEntityAccessType = (__meta_name === INSTANCE_TYPE_META_NAMES.ENTITY_ACCESS_TYPE);
        output.push({value: STAGE_TYPE_METAS.RESOURCE, label: `On the resource`});
        if(isEntityAccessType){
            output.push({value: STAGE_TYPE_METAS.ACCESSOR, label: `On the accessor`})
            output.push({value: STAGE_TYPE_METAS.ACCESSOR_PARENT, label: `On the parent of the accessor`})
        }
    }
    return output;
}

const getRelatedStructureForStageType = (instanceType, ticketStructure, stageType) => {
    const {TICKET, STATE, PARENT, RESOURCE, ACCESSOR, ACCESSOR_PARENT} = STAGE_TYPE_METAS;
    switch (stageType) {
        case TICKET:
            return ticketStructure; //TODO: take request type into consideration
        case STATE:
            return instanceType.structure;
        case PARENT:
            return instanceType.parent_type.structure;
        case RESOURCE:
            return instanceType.resource_type.structure;
        case ACCESSOR:
            return instanceType.accessor_type.structure;
        case ACCESSOR_PARENT:
            return instanceType.accessor_type.parent_type.structure;
        default:
            console.error(`ERROR: unexpected stage meta value: ${stageType}`)
            return null;
    }
}

const getUserFieldChoices = (instanceType, ticketStructure, stageType) => {
    const relatedStructure = getRelatedStructureForStageType(instanceType, ticketStructure, stageType);
    const choices = []
    for(const section of relatedStructure.sections){
        for(const field of section.fields){
            const isUserField = (field.value_type === FIELD_VALUE_TYPES.EMPLOYEE);
            const isFieldRequiredAtSubmission = (!section.metadata.hide_at_submission) && (!field.is_optional) && (!field.hide_conditions);
            if(isUserField && isFieldRequiredAtSubmission){
                choices.push({value: field.id, label: field.short_label});
            }
        }
    }
    return choices;
}

const canHaveFullResourceRemovalStage = (instanceType, requestType) => {
    const isRootEntityInstanceType = (instanceType.__meta_name === INSTANCE_TYPE_META_NAMES.ENTITY_INSTANCE_TYPE) && (!instanceType.parent_type);
    const isRemovalRequest = [ENTITY_REQUEST_TYPES.REMOVE, ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL].includes(requestType);
    return isRootEntityInstanceType && isRemovalRequest;
}

export const getStageConfigErrors = (stage, instanceType, ticketFormStructure) => {
    //TODO: take is_resource_access_removal_stage into consideration
    const { id, __meta_name, name, editable_sections, days_until_neglected, is_resource_access_removal_stage,
        confirmation_messages, role, can_defer, section_key, field_key} = stage;

        
    const errors = [];
    
    if(!__meta_name){
        return ['Must select a stage type']
    }
    if(!name) errors.push(`Must provide a name for the stage`);

    if(!days_until_neglected)errors.push(`Must provide value for days until the stage is considered negelected`);
    // else{
    //     const num = parseInt(days_until_neglected);
    //     if(isNaN(num) || (num <= 0) || (num.toString() !== days_until_neglected.toString())) errors.push(`Must provide a positive whole number for "days until neglected"`)
    //     if(days_until_neglected === '7xxxx') console.log('check', {num})
    // }

    const {APPROVE, REJECT, DEFER} = REVIEWER_ACTION_TYPES;
    if(!confirmation_messages[APPROVE]){
        errors.push(`Must provide a confirmation message for when a reviewer approves the request`);
    }

    if(__meta_name === STAGE_TYPE_METAS.ROLE){
        if(!role) errors.push("Must provide a user tag");
    }
    else if(__meta_name){
        const validFieldIds = getUserFieldChoices(instanceType, ticketFormStructure, __meta_name).map(c => c.value);
        if(!validFieldIds.includes(field_key)){
            errors.push(`Must provide a valid form field for the stage`)
        }
        else{
            const structureWithField = getRelatedStructureForStageType(instanceType, ticketFormStructure, __meta_name);
            const section = structureWithField.sections.find(s => s.id === section_key)
            if(!section || !section.fields.some(f => f.id === field_key)){
                errors.push("Issue validating the selected form field for the stage. Try unselecting and reselecting it.")
            }
        }
    }

    for(const sectionId of editable_sections){
        if(!ticketFormStructure.sections.some(s => s.id === sectionId)){
            errors.push(`Invalid editable sections selected`);
            break;
        }
    }
    if(hasDuplicates(editable_sections)) errors.push("Editable sections has duplicates");

    return errors;
    
}

export const AtomicStageConfigPanel = ({stage, setStage, instanceType, requestType, ticketFormStructure, hidePanel, userTags, refetchUserTags}) => {
    const { id, __meta_name, name, editable_sections, days_until_neglected, is_resource_access_removal_stage, confirmation_messages, role, can_defer, section_key, field_key} = stage;

    const stageTypeChoices = getStageTypeCoices(instanceType, requestType);
    const userTagChoices = userTags.map(tag => {return {value: tag.id, label: tag.label}});

    const setApproverField = (newFieldId) => {
        const structureWithField = getRelatedStructureForStageType(instanceType, ticketFormStructure, __meta_name);
        const section = structureWithField.sections.find(s => s.fields.some(f => f.id === newFieldId));
        setStage({...stage, section_key: section.id, field_key: newFieldId})
    }

    const canEditSections = [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN, ACCESS_REQUEST_TYPES.GRANT].includes(requestType)
    const editableSectionsChoices =  !canEditSections ? [] : ticketFormStructure.sections.map(s => {return {value: s.id, label: s.metadata.label}})

    const setDaysUntilNeglected = (nStr) => {
        if(nStr === ''){
            setStage({...stage, days_until_neglected: ''})
        }
        const num = parseInt(nStr);
        if(isNaN(num) || (num <= 0) || (num.toString() !== nStr)){
            return;
        }
        setStage({...stage, days_until_neglected: num})
    }
    const setStageType = (newStageType) => {
        const newCanDefer = (newStageType === STAGE_TYPE_METAS.TICKET) && can_defer;
        setStage({...stage, __meta_name: newStageType, can_defer: newCanDefer});
    }

    const canBeDeferable = (__meta_name === STAGE_TYPE_METAS.TICKET);
    return (
        <Box sx={SX_BOX_SIMPLE}>
            <Box alignItems="center" justifyContent='center' display='flex' marginBottom={2}>
                <IconButton onClick={hidePanel}>
                    <VisibilityOffIcon color='primary'/>
                </IconButton>
                <Typography variant='h6'>Stage Settings</Typography>
                <ConfigPanelHelpButton/>
            </Box>
            <Stack spacing={2}>
                <TicketTextField
                    label={'Stage name'}
                    value={name}
                    setValue={(x) => setStage({...stage, name: x})}
                    />
                <TicketSelectField
                    label={'Approvers for this stage are'}
                    value={__meta_name}
                    setValue={setStageType}
                    choices={stageTypeChoices}
                    />
                {
                    (__meta_name === STAGE_TYPE_METAS.ROLE) ?
                        <Stack direction='row'>
                            <TicketSelectField
                                label={'Tag'}
                                value={role ? role : ''}
                                setValue={(x) => setStage({...stage, role: x})}
                                choices={userTagChoices}
                                fullWidth={true}
                                />
                            <Tooltip title={'Sync tag choices'} enterDelay={1000} placement="top">
                                <IconButton onClick={refetchUserTags}>
                                    <SyncIcon color='primary'/>
                                </IconButton>
                            </Tooltip>
                        </Stack> :
                        <TicketSelectField
                            label={'Field'}
                            value={field_key ? field_key : ''}
                            setValue={setApproverField}
                            choices={getUserFieldChoices(instanceType, ticketFormStructure, __meta_name)}
                            />
                }

                <TicketTextField
                    label={'Days until neglected'}
                    value={days_until_neglected}
                    setValue={setDaysUntilNeglected}
                    />
                {
                    !canEditSections ? null :
                    <TicketMultiSelectField
                        label={'Form sections Editable by reviewer'}
                        value={editable_sections}
                        setValue={(x) => setStage({...stage, editable_sections: x})}
                        choices={editableSectionsChoices}
                        />
                }
                {
                    !canBeDeferable ? null :
                    <TicketCheckbox
                        label={'Reviewer can defer'}
                        value={!!can_defer}
                        setValue={(x) => setStage({...stage, can_defer: x})}
                        />
                }
                {
                    !canHaveFullResourceRemovalStage(instanceType, requestType) ? null :
                    <TicketCheckbox
                        label={'Is full access removal stage'}
                        value={!!is_resource_access_removal_stage}
                        setValue={(x) => setStage({...stage, is_resource_access_removal_stage: x})}
                        />
                }
                <ConfirmationMessagesField
                    confirmationMessages={confirmation_messages}
                    setConfirmationMessages={(x) => setStage({...stage, confirmation_messages: x})}
                    />
            </Stack>
        </Box>
    )
}