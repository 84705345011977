import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import { EXTENSIONS, post } from "../../helpers/requests";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import EmpForm, { getEmpFormErrorMsg } from "./EmpForm";


const AddEmployeeView = ({company, refetchCompany}) => {//maybe just pass an onSuccess function - refecthes company, closes panel, notifies success, etc
    // const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;
    const empStructure = company.employee_extra_data_structure;
    const {alertSuccess, alertError, staticData } = React.useContext(Context);

    const [emp, setEmp] = useState({first_name: "", last_name: "", email: "", extra_data: getDefaultSectionValues(empStructure)})
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);

    const onSubmitClick = () => {
        const errMsg = getEmpFormErrorMsg(emp);
        if(errMsg){
            alertError(errMsg);
            return;
        }
        const body = {...emp, status: "Active"};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Employee Added")
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.ADD_EMPLOYEE, body, onSuccess, onFailure)
    }

    return (
        <EmpForm
            emp={emp}
            setEmp={setEmp}
            empStructure={empStructure}
            submitState={submitState}
            onSubmitClick={onSubmitClick}
            />
    )
    // return (
    //     <Box>
    //         {/* <Typography justifyContent={'center'}>Add Employee</Typography> */}
    //         {/* <Stack direction={'row'}>
    //             <Typography justifyContent={'center'}>Add Employee</Typography>
    //         </Stack> */}
    //         <Stack spacing={2} marginY={2}>
    //             <TicketTextField
    //                 label={"First name"}
    //                 value={emp.first_name}
    //                 setValue={(x) => setEmp({...emp, first_name: x})}
    //                 fullWidth
    //                 />
    //             <TicketTextField
    //                 label={"Last name"}
    //                 value={emp.last_name}
    //                 setValue={(x) => setEmp({...emp, last_name: x})}
    //                 fullWidth
    //                 />
    //             <TicketTextField
    //                 label={"Email"}
    //                 value={emp.email}
    //                 setValue={(x) => setEmp({...emp, email: x})}
    //                 fullWidth
    //                 />
    //             <TicketSection
    //                 sectionStructure={empStructure}
    //                 value={extraData}
    //                 fieldSetterGetter={getFieldSetter}
    //                 sectionKey={'sectionKey'}
    //                 // showIrreleventFields={showIrreleventFields}
    //                 // hasBeenVisited={false}
    //                 />
    //         </Stack>
    //         <LoadButton onClick={onSubmitClick} loading={submitState===REQUEST_STATES.SENDING} disabled={submitState===REQUEST_STATES.SENDING}>
    //             Submit
    //         </LoadButton>
    //     </Box>
    // )
}

export default AddEmployeeView;