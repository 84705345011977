
export const SHORT_TEXT_LEN = 100
export const LONG_TEXT_LEN = 300
export const EXTRA_LONG_TEXT_LEN = 1000
export const MULTI_SELECT_LENGTH = 50//300
export const MAX_ENTITY_TYPE_NAME_LEN = 40;

export const ACTION_MSG_MAX_LEN = 300
export const ENTITY_NAME_MAX_LEN = 255;
export const CONFIG_LOCK_HOLD_TIME_MINUTES = 30


export const MULTI_SELECT_DELIM = "\n" //", "
export const MULTI_TEXT_DELIM = "\n"

export const SUBMITTER_ROLE = "Submitter";

export const EMPLOYEE_ACCESSOR_TYPE_ID = null;
export const TENANT_URL_PATH_PRECEDOR = 'client'

export const REQUEST_STATES = {
    NOT_SENT: "NOT_SENT",
    SENDING: "SENDING",
    SUCCEEDED: "SUCCEEDED",
    FAILED: "FAILED"
}

export const ACCESSOR_TYPES = {
    EMPLOYEE: "Employee",
    ENTITY: "Entity",
}

export const STAGE_TYPE_METAS = {
    ROLE: 'RoleApproverStage',
    TICKET: 'TicketFieldStage',
    STATE: 'InstanceStateFieldStage',
    PARENT: 'ParentFieldStage',
    RESOURCE: 'ResourceFieldStage',
    ACCESSOR: 'AccessorFieldStage',
    ACCESSOR_PARENT: 'AccessorParentFieldStage',
}

export const COMMON_REQUEST_TYPES ={
    EDIT: "Edit",
    EDIT_REQUEST: "Edit Request",
}

export const ACCESS_REQUEST_TYPES = {
    GRANT: "Grant",
    REMOVE: "Remove",
    // CHANGE: "Change",
    MANDATORY_REMOVAL: "Mandatory Removal",
    EDIT_REQUEST: "Edit Request",
    EDIT: "Edit",
    LVL_CHANGE: "Change"
}

export const TICKET_STATUS = {
    APPROVED: "Approved",
    PENDING: "Pending"
}

export const ENTITY_REQUEST_TYPES ={
    ADD: "Add",
    REOPEN: "Reopen",
    REMOVE: "Remove",
    RESOURCE_ACCESS_REMOVAL: "Resource Access Removal",
    AUTO_REMOVE: "Auto-Remove"
}

export const INSTANCE_TYPES ={
    ENTITY: "Entity",
    ACCESS: "Access",
}

export const EMPLOYEE_STATUSES ={
    ONBOARDING: "Onboarding",
    ACTIVE: "Active",
    OFFBOARDING: "Offboarding",
    INACTIVE: "Inactive",
}

export const ACCESS_STRUCTURE_IDS = {
    INFO_SECTION_ID: 'info',
    ACCESS_LVL_FIELD_ID: 'access_level_id',
    USERNAME_FIELD_ID: 'username'
}

export const UNABORTABLE_REQUEST_TYPES = [
    ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL,
    ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL
]

export const INSTANCE_TYPE_META_NAMES = {
    ENTITY_INSTANCE_TYPE: 'EntityInstanceType',
    EMPLOYEE_ACCESS_TYPE: 'EmployeeAccessType', 
    ENTITY_ACCESS_TYPE: 'EntityAccessType', 
}

export const REVIEWER_ACTION_TYPES = {
    APPROVE: 'Approve',
    REJECT: 'Reject',
    DEFER: 'Defer'
}

export const BULLET_CHAR = '\u2022';
export const MESSAGE_LIST_SEP = `\n    ${BULLET_CHAR} `

export const FALLBACK_FIELD_LABEL = '';
export const FALLBACK_SECTION_LABEL = '';
export const FALLBACK_STAGE_NAME = '';
export const FALLBACK_REVIEWER_NAME = '?';
export const FALLBACK_REVIEWER_ID = null;