import React from "react";
import { useEffect } from "react";
// import jwtDecode from "jwt-decode";
import {post, EXTENSIONS} from '../../helpers/requests'
import Context from "../../Store";
import { Box, Typography } from "@mui/material";
import { blue_light } from "../../Colors";
import { setAccessTypesRelatedTypes, sortChoicesInStaticData } from "../../helpers/StaticDataHelper";
import LoadingMessage from "../generic/LoadingMessage";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

const Login = ({tenantExtension, onLoggedIn}) => {
    const signDivId = "signInDiv12983796"
    const {alertSuccess, alertError, user, setUser, setStaticData } = React.useContext(Context);
    const [errMsg, setErrMsg] = React.useState(null);
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);

    function handleCallbackResponse(response){
        const token = response.credential;
        // var userObject = jwtDecode(token)
        // const users_name = userObject.given_name + " " +userObject.family_name

        const onSuccess = (response) => {
            // alertSuccess("Successfully logged in!")
            // setLoggedIn(users_name)
            // setUser(response.data.user)
            onLoggedIn()
            // setUser({...user, logged_in: true});

            // sortChoicesInStaticData(response.data.static_data)
            // setAccessTypesRelatedTypes(response.data.static_data)
            // setStaticData(response.data.static_data)
            setIsLoggingIn(false)
        }
            
        const onFail = (error) => {
            // alertError("Failed to login")
            setErrMsg("Failed to login");
            setIsLoggingIn(false)
        }
        setErrMsg(null);
        setIsLoggingIn(true);
        post(EXTENSIONS.LOGIN, {token: token, tenant_subdomain: tenantExtension}, onSuccess, onFail )
    }


    const initAndRenderGoogleSignIn = () => {
        const google = window.google;

        try {
            google.accounts.id.initialize({
                client_id: CLIENT_ID,
                callback: handleCallbackResponse,
                cancel_on_tap_outside: false,
                prompt_parent_id: signDivId,
                // ux_mode: "redirect",//"popup"
                itp_support: true,
            });
        } catch(err){ 
            console.log("Failed to initialize google.")
        }


        try {
            google.accounts.id.renderButton(
                document.getElementById(signDivId),
                {
                    theme: "outline",
                    size: "large",
                    type: "standard",
                    text: "continue_with",
                    shape: "rectangular",
                    width: 265
                }
            );
        } catch(err){ 
            console.log("Failed to render Google sign in button.")
        }

        try {
            google.accounts.id.prompt();
        } catch(err){ 
            console.log("Failed to prompt google 1 tap sign in.")
        }



        // console.log("google object:", window.google)
    }

    useEffect(initAndRenderGoogleSignIn, [])


    return(
        // <div>
            <Box bgcolor={blue_light} sx={{width: '100vw', height:'100vh'}}>
                <br/>
                <Typography variant="h3" align="center">Approvals Management System</Typography>
                <Typography variant="h5" align="center" marginY={3}>Please sign in</Typography>
                
                <div
                    id={signDivId}
                    align="center"
                    type="icon"
                    >
                </div>
                {
                    (errMsg === null) ? null : <Typography>{errMsg}</Typography>
                }
                {
                    !isLoggingIn ? null : <LoadingMessage/>
                }
            </Box>
        // </div>
    )
}

export default Login