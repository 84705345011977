import React, { useMemo, useState } from "react";
import { ACCESSOR_TYPES, ENTITY_REQUEST_TYPES, SUBMITTER_ROLE } from "../../helpers/Constants";
import { datetimeDisplay } from "../../helpers/FieldDisplayFormatters";
import AbstractTable from "../tables/AbstractTable"
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import TicketCheckbox from "../inputs/TicketCheckbox";
import TicketSelectField from "../inputs/TicketSelectField";
import { getSubmissionActionFromTicket, objToChoicesFlat, truncateString, uniqueifyBy } from "../../helpers/CommonUtil";
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from "react-router-dom";
import { isAccessTypeForEmp } from "../../helpers/access_helper";
import Context from "../../Store";
import { ACCESS_REQUEST } from "../../App";
import { sortChoicesByLabel } from "../../helpers/TicketFormHelper";
import { useTenantNavigate } from "../../helpers/CustomHooks";

const ANY = "any";
const ROW_HEIGHT = "small"

const NESTED_BOX_PROPS = {
    border: 2,
    borderRadius: 0,
    m: 0,
    p: 2
}


const AccessLevelCell = ({accessLevel}) => {
    if(!accessLevel) return "-None-";

    const tip = `${accessLevel.name} - ${accessLevel.description}`;
    return (
        <Tooltip title={<Typography>{tip}</Typography>} >
            <div>{accessLevel.name}</div>
        </Tooltip>        
    )
}

const TicketActionsTable = ({actions}) => {
    const {staticData} = React.useContext(Context);
    const columnNames = ['Stage', 'Actor', 'Action', 'Date', 'Note'];

    const renderRowCells = (action) => [
        action.role,
        action.actor_id ? staticData.all_users[action.actor_id] : "System",
        action.action,
        datetimeDisplay(action.datetime, true),
        <Tooltip title={<Typography>{action.message}</Typography>} >
            <div>{truncateString(action.message, 10, true)}</div>
        </Tooltip>
    ]

    return (
        <AbstractTable
            rowToKey={(action) => `${action.actor}-${action.datetime}`}
            rows={actions}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            rowHeight={ROW_HEIGHT}
            />

    )
}

const TicketsWithPaperTrailTable = ({access, accessLevels}) => {
    const [expandedTicket, setExpandedTicket] = useState(null);

    const ticketsCombined = useMemo(() => {
        const relevantResourceRequestTypes = [ENTITY_REQUEST_TYPES.REMOVE, ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL]
        let relevantResourceTickets = access.resource.tickets.filter(t => relevantResourceRequestTypes.includes(t.request_type))
        relevantResourceTickets = relevantResourceTickets.map(t => ({...t, is_on_resource: true}))
    
        const accessTickets = access.tickets.map(t => ({...t, is_on_resource: false}))
    
        const allTickets = accessTickets.concat(relevantResourceTickets);
        allTickets.sort((a, b) => {
            const aSubAct = a.actions.find(act => act.role === SUBMITTER_ROLE);
            const aKey = aSubAct ? aSubAct.datetime : "";
            const bSubAct = b.actions.find(act => act.role === SUBMITTER_ROLE);
            const bKey = bSubAct ? bSubAct.datetime : "";
            return aKey.localeCompare(bKey);
        })

        return allTickets
    }, [access])

    const rowToKey = (ticket) => `${ticket.id}-${ticket.is_on_resource}`;

    const columnNames = [ "Request Type", "Access Level", "Status", "Submitted at"];
    const renderRowCells = (ticket) => {
        const accessLevel = accessLevels?.find(a => a.id === ticket.form_values.info?.access_level_id);
        const submissionAction = getSubmissionActionFromTicket(ticket);
        const submissionTimeLabel = submissionAction ? datetimeDisplay(submissionAction.datetime, true) : "";

        let requestTypeText = ticket.request_type;
        if(ticket.is_on_resource && ticket.request_type === ENTITY_REQUEST_TYPES.REMOVE) requestTypeText = `Removal of ${access.resource.name}`
        return [
            requestTypeText,
            <AccessLevelCell accessLevel={accessLevel}/>,
            // accessLevel ? accessLevel.name : "-", //todo: fix, accessLevels is an array, not an object, so it is indexed by positions, not key. fix in highest level table component
            ticket.status,
            submissionTimeLabel
        ]
    }

    const renderRowExpansion = (ticket) => {
        if(expandedTicket !== ticket) return null;
        const ticketId = ticket.id;

        return (
            <Box {...NESTED_BOX_PROPS}>
                <Typography>Review Actions on above Ticket (newest to oldest)</Typography>
                <TicketActionsTable actions={ticket.actions} key={ticketId}/>          
            </Box>
        ) 
    }

    const onRowClick = (ticket) => {
        console.log('ticket row click', {expandedTicket, ticket})
        setExpandedTicket(ticket === expandedTicket ? null : ticket)
    }


    return (
        <AbstractTable
            rows={ticketsCombined}
            rowToKey={rowToKey}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            renderRowExpansion={renderRowExpansion}
            onRowClick={onRowClick}
            rowHeight={ROW_HEIGHT}
            expandedRow={expandedTicket}
            />
    )
}

// this is the full trail including everything
const CurrAccessesWithPaperTrailTable = ({accessType, accesses, allAccessLevels}) => {
    //entityNames is like in bulkImport choices, but proccessed sp that instead of value/label choices, it it a map/object of id->name
    //check if accessLevels is list or map of id to name
    const {staticData} = React.useContext(Context);
    const [expandedAccessInfo, setExpandedAccessInfo] = useState(null);
    const navigate = useTenantNavigate()


    const rowToKey = (access) => `${access.accessor.id}-${access.resource.id}`;
    const is_accessor_emp = isAccessTypeForEmp(accessType);
    const is_accessor_member = !!accessType.accessor_type?.parent_type

    const columnNames = [ // 5 columns
        accessType.resource_type.name,// + " (Resource)",
        is_accessor_emp ? "Employee" : accessType.accessor_type.name,
        // is_accessor_member ? "Member" : "",
        "Username",
        "Access Level",
        ""
    ];

    const onNavigateClick = (accessInfo) => {
        // console.log("onNavigateClick - need to impl")
        const accessorId = accessInfo.accessor.id;
        const resourceId = accessInfo.resource.id;
        navigate(`/${ACCESS_REQUEST}/access_type/${accessType.id}/accessor/${accessorId}/resource/${resourceId}`);
    }

    const renderRowCells = (accessInfo) => {
        const resourceId = accessInfo.resource.id;
        const hasAccessLevel = !!(accessInfo.state.form_values.info?.access_level_id);
        const hasUsername = !!(accessInfo.state.form_values.info?.username);

        const accessLevel = (accessInfo.is_active && hasAccessLevel) ?
            allAccessLevels[resourceId].find(a => a.id === accessInfo.state.form_values.info.access_level_id) :
            null;

        let accessorName = accessInfo.accessor.name;
        if(is_accessor_member) accessorName += ` (${(accessInfo.accessor.parent.name)})`
        return [
            accessInfo.resource.name,
            accessorName,
            (accessInfo.is_active && hasUsername) ? accessInfo.state.form_values.info.username : "-",
            <AccessLevelCell accessLevel={accessLevel}/>,
            <Tooltip title={<Typography>view other details for this access</Typography>} placement="top">
                <IconButton onClick={() => onNavigateClick(accessInfo)}>
                    <VisibilityIcon color="primary"/>
                </IconButton>
            </Tooltip>
            // accessLevel ? accessLevel.name : "-None-"
        ]
    }

    const renderRowExpansion = (accessInfo) => {
        if(accessInfo !== expandedAccessInfo) return null;

        return (
            <Box {...NESTED_BOX_PROPS}>
                <Typography>Ticket history</Typography>
                <TicketsWithPaperTrailTable
                    access={accessInfo}
                    accessLevels={allAccessLevels[accessInfo.resource.id]}
                    />
            </Box>
        )
    }

    const onRowClick = (accessInfo) => setExpandedAccessInfo(accessInfo === expandedAccessInfo ? null : accessInfo)
    return (
        <AbstractTable
            rows={accesses}
            rowToKey={rowToKey}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            renderRowExpansion={renderRowExpansion}
            onRowClick={onRowClick}
            onRowHover={undefined}
            key={accessType.id}
            rowHeight={ROW_HEIGHT}
            expandedRow={expandedAccessInfo}
            initialRowsPerPage={10}
            rowsPerPageOptions={[1, 10, 25, accesses.length]}
            />
    )
}


const filterAccesses = (accessType, accesses, filterCriteria) => {
    // console.log('accesses', accesses)
    const {includePastAccess, resourceId, accessorId, accessorMemberId, accessorParentId} = filterCriteria;
    const includePastAccessFilter = (a) => includePastAccess || a.is_active;
    const resourceFilter = (a) => (resourceId === ANY) || (a.resource.id === resourceId);
    const accessorParentFilter = (a) => (accessorParentId === ANY) || (a.accessor.parent.id == accessorParentId);
    const accessorFilter = (a) => (accessorId === ANY) || (a.accessor.id === accessorId);

    const allFilters = [includePastAccessFilter, resourceFilter, accessorParentFilter, accessorFilter];
    const comboFilter = (a) => {
        for(let i = 0; i < allFilters.length; i++){
            const currFilter = allFilters[i];
            if(!currFilter(a)){
                return false;
            }
        }
        return true;
    }

    return accesses.filter(a => comboFilter(a))
}
const makeEmptyFilterCriteria = () => ({includePastAccess: false, resourceId: ANY, accessorId: ANY, accessorMemberId: ANY, accessorParentId: ANY});

const FilterController = ({value, setValue, accessType, accesses}) => {
    const {includePastAccess, resourceId, accessorId, accessorParentId} = value;
    // const {id, accessor_entity_type_name, is_accessor_member, resource_entity_type_name, is_resource_member, structure} = accessType;

    const [isCollapsed, setIsCollapsed] = useState(false);

    let resourceChoices = accesses.map(a => {return {value: a.resource.id, label: a.resource.name}})
    resourceChoices = uniqueifyBy(resourceChoices, (choice) => choice.value)
    sortChoicesByLabel(resourceChoices);
    resourceChoices.unshift({value: ANY, label: ANY})

    const isAccessorMember = !!(accessType.accessor_type && accessType.accessor_type.parent_type)
    let accessorParentChoices = [];
    if(isAccessorMember){
        accessorParentChoices = accesses.map(a => {return {value: a.accessor.parent.id, label: a.accessor.parent.name}})
        accessorParentChoices = uniqueifyBy(accessorParentChoices, (choice) => choice.value)
        sortChoicesByLabel(accessorParentChoices);
    }
    accessorParentChoices.unshift({value: ANY, label: ANY})
    
    let accessorChoices = 
        (accessorParentId === ANY) ?
        accesses.map(a => {return {value: a.accessor.id, label: a.accessor.name}}) :
        accesses.filter(a => a.accessor.parent.id === accessorParentId).map(a => {return {value: a.accessor.id, label: a.accessor.name}});

    accessorChoices = uniqueifyBy(accessorChoices, (choice) => choice.value)
    sortChoicesByLabel(accessorChoices);
    accessorChoices.unshift({value: ANY, label: ANY})

    return (
        <Stack direction={'row'} spacing={1} border={2} borderRadius={2} p={2} alignItems={"center"}>
            <TicketCheckbox
                label={"Include inactive access"}
                value={includePastAccess}
                setValue={(x) => setValue({...value, includePastAccess: x})}
                />
            {
                !isAccessorMember ? null :
                <TicketSelectField
                    label={'Accessor Root'}
                    value={accessorParentId}
                    setValue={(x) => setValue({...value, accessorParentId: x, accessorId: ANY})}
                    choices={accessorParentChoices}
                    fullWidth
                    />
            }
            <TicketSelectField
                label={`Accessor`}
                value={accessorId}
                setValue={(x) => setValue({...value, accessorId: x})}
                choices={accessorChoices}
                fullWidth
                />
            <TicketSelectField
                label={'Resource'}
                value={resourceId}
                setValue={(x) => setValue({...value, resourceId: x})}
                choices={resourceChoices}
                fullWidth
                />
            <Tooltip title={<Typography>Clear all filters (show all)</Typography>}>
                <IconButton onClick={() => setValue(makeEmptyFilterCriteria())}>
                    <RefreshIcon fontSize="small" color="primary"/>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}

const CurrAccessesWithPaperTrail = ({accessType, accesses, allAccessLevels}) => {
    // toggle including past access
    // filter by software, accessor group, accessor member, current access levels (exact, or greater than/equal)
    const [filterCriteria, setFilterCriteria] = useState(makeEmptyFilterCriteria());

    //filter accesses based on criteria
    const filteredAccesses = filterAccesses(accessType, accesses, filterCriteria);

    return (
        <Box>
            <FilterController
                value={filterCriteria}
                setValue={setFilterCriteria}
                accessType={accessType}
                accesses={accesses}
                />
            <CurrAccessesWithPaperTrailTable
                accessType={accessType}
                accesses={filteredAccesses}
                allAccessLevels={allAccessLevels}
                />
        </Box>
    )
}

export default CurrAccessesWithPaperTrail;