import React, {useEffect, useState} from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import PageTitle from "../generic/PageTitle"
import { useNavigate, useParams } from "react-router-dom"
import Context from "../../Store"
import TicketSelectField from "../inputs/TicketSelectField"
import { ACCESSOR_TYPES } from "../../helpers/Constants"
import { ACCESS_REQUEST } from "../../App"
import { getActiveEmpChoices, getActiveEntityChoices, getAllEntityChoices, getEntityInstanceTypeById, getEntityTypeWithInstanceTypeId } from "../../helpers/StaticDataHelper"
import { _EMP_META_LABEL } from "../../helpers/access_helper"
import { sortChoicesByLabel } from "../../helpers/TicketFormHelper"
import { useEnsureUpToDateConfigs, useTenantNavigate } from "../../helpers/CustomHooks"
import LoadingMessage from "../generic/LoadingMessage"

export const getAccessTypeChoices = (staticData, access_types) => {
    return access_types.map((a) => {
        let accessorTypeName = a.__meta_name === _EMP_META_LABEL ? "Employee" : getEntityInstanceTypeById(staticData, a.accessor_instance_type_id).name;
        const resourceTypeName = getEntityInstanceTypeById(staticData, a.resource_instance_type_id).name;
        return {
            value: a.id,
            label: `${accessorTypeName} Access to ${resourceTypeName}` 
        }
    })
}


const getAccessorChoices = (accessType, staticData) => {
    const {__meta_name, accessor_instance_type_id, resource_instance_type_id} = accessType;
    switch (__meta_name) {
        case undefined:
            return []
        case _EMP_META_LABEL:
            return getActiveEmpChoices(staticData)
        default:
            return getAllEntityChoices(staticData, accessor_instance_type_id, null);
    }
}

const AccessSelection = () => {
    const configSyncState = useEnsureUpToDateConfigs();
    const navigate = useTenantNavigate()

    const {staticData, alertError} = React.useContext(Context);
    const {access_types, active_users, entity_summaries} = staticData; 

    const DEF_VAL = "";
    const [accessTypeId, setAccessTypeId] = useState(DEF_VAL);
    const [accessorRootId, setAccessorRootId] = useState(DEF_VAL);
    const [accessorMemberId, setAccessorMemberId] = useState(DEF_VAL);
    const [resourceId, setResourceId] = useState(DEF_VAL);

    if(configSyncState === false) return <div>Failed to load</div>
    if(configSyncState === null) return <LoadingMessage/>

    const isAccessTypeSelected = (accessTypeId !== DEF_VAL);
    const accessType = isAccessTypeSelected ? access_types.find(a => a.id === accessTypeId) : {};

    const {__meta_name, accessor_instance_type_id, resource_instance_type_id} = accessType;
    const isEmpAccess = __meta_name === _EMP_META_LABEL;
    const accessorRootChoices = sortChoicesByLabel(getAccessorChoices(accessType, staticData));

    
    const accessorEntityType = getEntityTypeWithInstanceTypeId(staticData, accessor_instance_type_id);
    const accessorTypeLabel = accessorEntityType ? accessorEntityType.name : ACCESSOR_TYPES.EMPLOYEE;
    const resourceTypeLabel = !isAccessTypeSelected ? "" : getEntityTypeWithInstanceTypeId(staticData, resource_instance_type_id).name;
    
    const isAccessorMember = accessorEntityType && accessorEntityType.member_instance_type && (accessorEntityType.member_instance_type.id === accessor_instance_type_id);
    
    // const isNextBtnEnabled = getIsNextBtnEnabled(accessType, accessorInstanceId, accessorMemberId, resourceInstanceId);
    const isNextBtnEnabled = isAccessTypeSelected && accessorRootId && resourceId && (!isAccessorMember || accessorMemberId);
    const onNextClick = () => {
        const accessorId = isAccessorMember ? accessorMemberId : accessorRootId;
        navigate(`/${ACCESS_REQUEST}/access_type/${accessTypeId}/accessor/${accessorId}/resource/${resourceId}`)
    }
    return (
        <Box>
            <Stack spacing={2} padding={4} borderRadius={2} borderColor={'#000000'} border={2}>
                <Typography variant="h4">Select Accessor and Resource</Typography>
                <TicketSelectField
                    label="Access Type"
                    value={accessTypeId}
                    setValue={setAccessTypeId}
                    choices={getAccessTypeChoices(staticData, access_types)}
                    />
                {
                    isAccessTypeSelected &&
                    <TicketSelectField
                        label={accessorTypeLabel}
                        value={accessorRootId}
                        setValue={setAccessorRootId}
                        choices={accessorRootChoices}
                        idPrefix={accessorTypeLabel}
                        />
                }
                {
                    isAccessTypeSelected && isAccessorMember && accessorRootId &&
                    <TicketSelectField
                        label={`${accessorTypeLabel} Member`}
                        value={accessorMemberId}
                        setValue={setAccessorMemberId}
                        choices={getAllEntityChoices(staticData, accessor_instance_type_id, accessorRootId)}
                        idPrefix={`${accessorTypeLabel} ${accessorRootId}`}
                        />
                }
                {
                    isAccessTypeSelected &&
                    <TicketSelectField
                        label={resourceTypeLabel}
                        value={resourceId}
                        setValue={setResourceId}
                        choices={getAllEntityChoices(staticData, resource_instance_type_id, null)}
                        idPrefix={resourceTypeLabel}
                        />
                }
                {
                    <Button disabled={!isNextBtnEnabled} onClick={onNextClick}>
                        Next
                    </Button>
                }
            </Stack>
        </Box>
    )
}

const AccessSelectionPage = () => {
    // const {entity_type_name, entity_type_instance_id} = useParams();

    let title = `Request Access`;

    return (
        <div key={`AccessTypeSelectionPage`}>
            <PageTitle title={title}/>
            <AccessSelection/>
        </div>
    )
}

export default AccessSelectionPage;
