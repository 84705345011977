import { Box, Divider, Stack, Typography } from "@mui/material"
import { useParams } from 'react-router-dom';
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import React, { useEffect, useState } from "react";
import Context from "../../Store";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { datetimeDisplay } from "../../helpers/FieldDisplayFormatters";
import { isAccessTypeForEmployee } from "../../helpers/StaticDataHelper";
import AccessHeader from "./AccessHeader";
import ApproverAccessTicketReviewDirect from "./ApproverAccessTicketReviewDirect";
import { getAccessStructureCopyWithAccessLevels } from "../../helpers/access_helper";

const ReviewAccessTicketPage = () => {
    const configSyncState = useEnsureUpToDateConfigs()

    const params = useParams();

    const access_type_id = Number(params.access_type_id);
    const accessor_id = Number(params.accessor_id);
    const resource_id = Number(params.resource_id);
    const ticket_id = Number(params.ticket_id);
    const stage_id = Number(params.stage_id);

    const {staticData} = React.useContext(Context);
    const {access_types} = staticData;
    const accessType = access_types.find(at => at.id === access_type_id);
    const isEmpAccess = isAccessTypeForEmployee(accessType); //TODO: request body should not depend on cached static data

    const body ={
        instance_desc: {
            instance_type: "Access",
            is_emp_access: isEmpAccess,
            accessor_id: accessor_id,
            resource_id: resource_id,
        },
        ticket_id: ticket_id,
        stage_id: stage_id
    };
    const task = useData(EXTENSIONS.GET_MY_PENDING_TASK, body);
    const accessLevelsData = useData(EXTENSIONS.GET_ACCESS_LEVELS, {'resource_id': resource_id});
    
    const datas = [task, accessLevelsData, configSyncState];
    if(datas.includes(false)) return <div>Failed to Load...</div>;
    if(datas.includes(null)) return <LoadingMessage/>;
    
    const structure = getAccessStructureCopyWithAccessLevels(accessType, accessLevelsData.access_levels);

    const {instance, ticket, stage} = task;
    const requestType = ticket.request_type;

    return (
        <div key={`ReviewTicketPage-${access_type_id}-${ticket_id}-${stage_id}`}>
            <AccessHeader
                title={`Review Request to ${requestType} Access`}
                subtitle={`Stage: ${stage.stage_name}`}
                accessInstance={instance}
                accessType={accessType}
                />
            <ApproverAccessTicketReviewDirect
                accessTypeId={access_type_id}
                task={task}
                structure={structure}
                />
        </div>
    )
            
}

export default ReviewAccessTicketPage;
