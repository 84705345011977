import { useContext, useState } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { blue_light } from "../../../Colors";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import { MyDivider } from "../../generic/MyDivider";
import { VerticalDivider } from "../../generic/Dividers";
import { getBgColor, HRISDataMapperButtonPanel, MappingSectionHeader, STATE_CHANGE_NOTE } from "./HRISDataMapperHelper";
import { BULLET_CHAR } from "../../../helpers/Constants";
import Context from "../../../Store";
import LabelValueDisplay from "../../generic/LabelValueDisplay";

const AUTOMAP_TOOLTIP = `\
Auto-map people by uniquely matching email. You should verify the mapping after clicking. If 2 people in your HRIS have the same email as a tracked user, they will not be mapped. If the email is similar but not
exactly the same, the user will not be mapped. Note that this may undo mappings you have already started
`

const MAPPING_DESCRIPTION = `\
A new user will NOT be created for these tracked users. Instead they will be associated with the selected employee in your HRIS and update with them.
`



export const ExternalEmpDTOItem = ({empDTO, onClick, isHilighted}) => {
    const {first_name, last_name, employment_status, emails, department_external_id, manager_external_id} = empDTO;
    const fullName = `${first_name} ${last_name}`;

    const tooltip = (
        <Box>
            <Typography>Status: {employment_status}</Typography>
            <Typography>Emails: {(emails.length === 0) ? '<none>' : emails.join(", ")}</Typography>
        </Box>
    )
    return (
        <Stack direction='row' onClick={onClick} bgcolor={getBgColor(isHilighted)} spacing={1}>
            <Tooltip title={tooltip}  placement="left">
                <InfoOutlinedIcon color='primary'/>
            </Tooltip>
            <Typography>{fullName}</Typography>

        </Stack>
    )
}
const InternalEmpItem = ({emp, onClick, isHilighted}) => {
    
    const tooltip = (
        <Box>
            <Typography>Status: {emp.status}</Typography>
            <Typography>Email: {emp.email}</Typography>
            {
                (emp.role_tags.length === 0) ? null :
                <Typography>Tags: {emp.role_tags.map(t => t.label).join(", ")}</Typography>

            }
        </Box>
    )
    return (
        <Stack direction='row' onClick={onClick} bgcolor={getBgColor(isHilighted)} spacing={1}>
            <Tooltip title={tooltip}  placement="left">
                <InfoOutlinedIcon color='primary'/>
            </Tooltip>
            <Typography>{emp.name}</Typography>

        </Stack>
    )
}


const sortedInternalEmpIds = (empIds, idToEmpObj) => {
    return [...empIds].sort((a, b) => idToEmpObj[a].name.localeCompare(idToEmpObj[b].name))
}

const sortedExternalEmpIds = (extEmpIds, idToEmpDTOObj) => {
    return [...extEmpIds].sort((a, b) => {
        const aName = `${idToEmpDTOObj[a].first_name} ${idToEmpDTOObj[a].last_name}`;
        const bName = `${idToEmpDTOObj[b].first_name} ${idToEmpDTOObj[b].last_name}`;
        return aName.localeCompare(bName)
    })
}

const EmployeeMapperInstructions = () => {
    return (
        <Box sx={SX_BOX_SIMPLE}>
            <Typography>
                {BULLET_CHAR} There is likely an overlap between people in your HRIS and users that have been tracked in AxoTrax.
            </Typography>
            <Typography>
                {BULLET_CHAR} Complete the mapping below. This lets us know that someone in your HRIS and someone tracked in AxoTrax are the same person.
            </Typography>
            <Typography>
                {BULLET_CHAR} This will provide a smooth transition, where there are not duplicate users being tracked for the same person
                and active users do get offboarded in AxoTrax (which would trigger relevant access removal processes for them and invalidate roles they hold).
            </Typography>
            <Typography>
                {BULLET_CHAR} Select an unmapped employee and an unmapped user to create the mapping between them.
            </Typography>
            <Typography>
                {BULLET_CHAR} {STATE_CHANGE_NOTE}
            </Typography>
        </Box>
    )
}


export const HRISIntegrationEmployeeMapper = ({internalCompany, externalCompanyDTO, dataMap, setDataMap, advanceStep, goBackStep}) => {
    const {user, alertError} = useContext(Context);
    const currUserId = user.user_id;

    const {internal_to_external_emp_id_map, unmapped_internal_emp_ids, unmapped_external_emp_ids} = dataMap;
    const {employee_dtos} = externalCompanyDTO;

    const [selectedInternalEmpId, setSelectedInternalEmpId] = useState(null);
    const [selectedExternalEmpId, setSelectedExternalEmpId] = useState(null);

    const externalIdToEmpDTO = {}
    for(const empDTO of employee_dtos) externalIdToEmpDTO[empDTO.external_id] = empDTO;

    const internalIdToEmp = {}
    for(const emp of internalCompany.employees) internalIdToEmp[emp.id] = emp;

    const onUnmappedExternalEmpDTOClick = (externalId) => {
        if(!selectedInternalEmpId) setSelectedExternalEmpId(externalId);
        else createSingleMapping(selectedInternalEmpId, externalId)
    }
    const onUnmappedInternalEmpDTOClick = (internalId) => {
        if(!selectedExternalEmpId) setSelectedInternalEmpId(internalId);
        else createSingleMapping(internalId, selectedExternalEmpId)
    }
    const createSingleMapping = (internalEmpId, externalEmpId) => {
        // if(!internalEmpId || !externalEmpId) return;
        internal_to_external_emp_id_map[internalEmpId] = externalEmpId;
        const newUnmappedInternalIds = unmapped_internal_emp_ids.filter(id => id !== internalEmpId)
        const newUnmappedExternalIds = unmapped_external_emp_ids.filter(id => id !== externalEmpId)
        setDataMap({...dataMap, unmapped_internal_emp_ids: newUnmappedInternalIds, unmapped_external_emp_ids: newUnmappedExternalIds})
        setSelectedInternalEmpId(null);
        setSelectedExternalEmpId(null);
    }
    const onRemoveMappingClick = (internalId) => {
        const externalId = internal_to_external_emp_id_map[internalId];
        delete internal_to_external_emp_id_map[internalId];
        unmapped_internal_emp_ids.push(internalId);
        unmapped_external_emp_ids.push(externalId);
        setDataMap({...dataMap})
    }

    const onAutoMapClick = () => {
        const unmappedInternal = [];
        const unmappedExternal = [];
        const mapping = {};

        for(const emp of internalCompany.employees){
            if(!emp.email){
                unmappedInternal.push(emp.id)
            }
            else{
                const empDTOsWithEmail = employee_dtos.filter(e => e.emails.includes(emp.email));
                if(empDTOsWithEmail.length === 1){
                    mapping[emp.id] = empDTOsWithEmail[0].external_id;
                }
                else{
                    unmappedInternal.push(emp.id)
                }
            }
        }

        const mappedExternalIds = Object.values(mapping);
        for(const empDTO of employee_dtos){
            if(!mappedExternalIds.includes(empDTO.external_id)){
                unmappedExternal.push(empDTO.external_id)
            }
        }

        const newDataMap = {
            ...dataMap,
            internal_to_external_emp_id_map: mapping,
            unmapped_internal_emp_ids: unmappedInternal,
            unmapped_external_emp_ids: unmappedExternal
        }
        setDataMap(newDataMap);
    }

    const onDoneClick = () => {
        //curr user must be mapped
        if(!internal_to_external_emp_id_map[currUserId]){
            alertError("You must map yourself to an employee in your HRIS.")
            return;
        }
        // if(!getCurrUsersNewLoginEmail(currUserId, emailDomainWhitelist, externalCompanyDTO, dataMap)){
        //     alertError("You will not have a login email. either you mis-mapped yourself, or did not whitelist a domain for an email your have in your HRIS.")
        //     return;
        // }
        advanceStep();
    }

    return (
        <Box>
            <Typography variant='h6'>Map Employees</Typography>
            <EmployeeMapperInstructions/>

            <HRISDataMapperButtonPanel goBackStep={goBackStep} advanceStep={onDoneClick} onAutoMapClick={onAutoMapClick} autoMapTooltip={AUTOMAP_TOOLTIP}/>
            <Typography marginTop={-1.5} marginBottom={2}>
                Moving backwards from this stage will reset any of your input during this step (and future steps), but your vault settings will not be undone.
            </Typography>

            <Stack direction='row' spacing={2} sx={SX_BOX_SIMPLE}>
                <Stack width='25%'>
                    <MappingSectionHeader
                        title={'Unmapped Employees in your HRIS'}
                        description={'If you do not map them to a tracked user, then AxoTrax will create a new user for this person'}
                        />
                    {
                        sortedExternalEmpIds(unmapped_external_emp_ids, externalIdToEmpDTO).map(externalId => {
                            const empDTO = externalIdToEmpDTO[externalId];
                            return (
                                <ExternalEmpDTOItem
                                    key={externalId} empDTO={empDTO} onClick={() => onUnmappedExternalEmpDTOClick(externalId)}
                                    isHilighted={selectedExternalEmpId === externalId}/>
                            )
                        })
                    }
                </Stack>

                <VerticalDivider/>
                
                <Stack width='25%'>
                    <MappingSectionHeader
                        title={'Unmapped users tracked in AxoTrax'}
                        description={'If you do not map these users to a person in your HRIS, they will be offboarded'}
                        />
                    {
                        sortedInternalEmpIds(unmapped_internal_emp_ids, internalIdToEmp).map(internalId => {
                            const emp = internalIdToEmp[internalId];
                            if(!emp){
                                console.log('null emp', {emp, internalIdToEmp})
                            }
                            return (
                                <InternalEmpItem
                                    key={internalId} emp={emp} onClick={() => onUnmappedInternalEmpDTOClick(internalId)}
                                    isHilighted={selectedInternalEmpId === internalId}/>
                            )
                        })
                    }
                </Stack>
                
                <VerticalDivider/>
                
                <Stack width='50%' spacing={1}>
                    <MappingSectionHeader
                        title={'Mappings'}
                        description={MAPPING_DESCRIPTION}
                        />
                    {
                        sortedInternalEmpIds(Object.keys(internal_to_external_emp_id_map), internalIdToEmp).map(internalId => {
                            const externalId = internal_to_external_emp_id_map[internalId];
                            const emp = internalIdToEmp[internalId];
                            const empDTO = externalIdToEmpDTO[externalId];
                            if(!emp){
                                console.log('null emp (mapped)', {emp, internalIdToEmp})
                            }
                            return (
                                <Stack key={internalId} direction='row' spacing={2} alignItems='center'>
                                    <Button onClick={() => onRemoveMappingClick(internalId)} variant='outlined'>
                                        Unmap
                                    </Button>
                                    <ExternalEmpDTOItem empDTO={empDTO}/>
                                    <Typography>→</Typography>
                                    <InternalEmpItem emp={emp}/>
                                </Stack>
                            )
                        })
                    }
                </Stack>
            </Stack>


        </Box>
    )
}