import { useContext } from "react"
import Context from "../../Store"
import { Box, Typography } from "../../../node_modules/@mui/material/index";


export const HomePage = () => {
    const {user} = useContext(Context);

    const welcomeText = `Welcome, ${user.full_name}`;

    return (
        <Box justifyContent='center' display='flex'>
            <Typography variant='h4'>{welcomeText}</Typography>
            <Box width={240}/>
        </Box>
    )
}