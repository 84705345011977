import React from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Context from "../../Store";
import { useSearchParams } from "react-router-dom";
import PermissionsSectionForm from "./PermissionSectionForm";
import { ALL_PERMS } from "./perm_constants";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import LoadButton from "../generic/LoadButton";
import { REQUEST_STATES } from "../../helpers/Constants";
import { EXTENSIONS, post } from "../../helpers/requests";
import { getEntityInstanceTypeById, getEntityTypeWithInstanceTypeId, getFieldStructureFromFormStructure } from "../../helpers/StaticDataHelper";
import LabelValueDisplay from "../generic/LabelValueDisplay";

const HELP_TOOLTIP = `
Hover over the texts for details.
An Access Instance has 2 parts: the Accessor and the Resource.
For example, in John Doe's access to some software, John Doe is the Accessor
and the software is the resource.
`

const MEMBERS_NOTE = `
*Note regarding permission for adding members (via request, override or bulk import):
In order to grant permission for adding a member, you must mark the checkbox that is located in the *Root* section.
The checkboxes in the Members section for *adding* an entity are meaningless and will be removed in the future. 
`
const getInitialPermsState = (permSections) => {
    const values = {
        root_section: {},
        member_section: {},
        accessor_root_section: {},
        accessor_member_section: {},
        resource_root_section: {},
        resource_member_section: {},
    }

    const rootSectionPerms = makeNestedDeepCopy({...ALL_PERMS.GENERAL_ACTIONS, ...ALL_PERMS.ENTITY_ACTIONS})
    // delete rootSectionPerms.REQ_ADD
    // delete rootSectionPerms.OVERRIDE_ADD
    // delete rootSectionPerms.BULK_IMPORT
    
    Object.values(rootSectionPerms).forEach(p => {
        const permKey = p.key;
        values.root_section[permKey] = !!permSections.root_section.permissions[permKey]
    })
    
    const memberSectionPerms = makeNestedDeepCopy({...ALL_PERMS.GENERAL_ACTIONS, ...ALL_PERMS.ENTITY_ACTIONS})
    // delete memberSectionPerms.REQ_ADD
    // delete memberSectionPerms.OVERRIDE_ADD
    // delete memberSectionPerms.BULK_IMPORT
    Object.values(memberSectionPerms).forEach(p => {
        const permKey = p.key;
        values.member_section[permKey] = !!permSections.member_section.permissions[permKey]
    })
    
    
    const accessSectionPerms = makeNestedDeepCopy({...ALL_PERMS.GENERAL_ACTIONS, ...ALL_PERMS.ACCESS_ACTIONS})
    const accessSectionNames = [ 'accessor_root_section', 'accessor_member_section', 'resource_root_section', 'resource_member_section' ]
    accessSectionNames.forEach(accessSectionName => {
        Object.values(accessSectionPerms).forEach(p => {
            const permKey = p.key;
            values[accessSectionName][permKey] = !!permSections[accessSectionName].permissions[permKey]
        })
    })

    return {
        values: values,
        expectedPerms: {
            root_section: rootSectionPerms,
            member_section: memberSectionPerms,
            access_sections: accessSectionPerms,
        }
    }

}

const EditEntityRoleProfileView = ({permissions, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams();

    const profileId = Number(searchParams.get('profile_id'));
    const profile = permissions.entity_role_profiles.find(p => p.id === profileId);
    const {values: initialValues, expectedPerms} = getInitialPermsState(profile)

    const [values, setValues] = React.useState(initialValues);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const isSubmitting = (submitState === REQUEST_STATES.SENDING);

    const entityType = getEntityTypeWithInstanceTypeId(staticData, profile.root_instance_type_id);
    const instanceType = entityType.root_instance_type;
    const roleFieldStructure = getFieldStructureFromFormStructure(instanceType.structure, profile.role_field_desc);

    const entityTypeName = instanceType.name;
    const roleName = roleFieldStructure ? roleFieldStructure.short_label : '';

    const canEntityHaveMembers = !!entityType.member_instance_type;
    const canRootEntitiesBeAccessor = staticData.access_types.some(at => at.accessor_instance_type_id === instanceType.id);
    const canMemberEntitiesBeAccessor = canEntityHaveMembers && staticData.access_types.some(at => at.accessor_instance_type_id === entityType.member_instance_type.id);
    const canRootEntitiesBeResource = staticData.access_types.some(at => at.resource_instance_type_id === instanceType.id);

    const setValue = (sectionKey, permKey, x) => {
        setValues({...values, [sectionKey]: {...values[sectionKey], [permKey]: x}})
    }

    const setAllValues = (sectionKey, x) => {
        const newValues = {};
        for(const key of Object.keys(values[sectionKey])){
            newValues[key] = x;
        }        
        setValues({...values, [sectionKey]: newValues})
    }

    const onSaveClick = () => {
        const body = {...makeNestedDeepCopy(values), profile_id: profileId};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Profile Edited")
            refetchPermissions();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.EDIT_ENTITY_ROLE_PERM_PROF, body, onSuccess, onFailure)
    }
    return (
        <Box>
            <Box marginBottom={1}>
                <Stack direction={'row'} spacing={1}>
                    <Typography variant="h5" alignContent={'center'}>Edit Process Role Permission Profile</Typography>
                    <Tooltip title={<Typography>{HELP_TOOLTIP}</Typography>}>
                        <HelpOutlineIcon color="primary"/>
                    </Tooltip>
                </Stack>
                <LabelValueDisplay label={"Entity type with role"} value={entityTypeName}/>
                <LabelValueDisplay label={"Role"} value={roleName}/>
                {/* <Typography variant="h6" fontWeight={'light'}>Form type with role: {entityTypeName}</Typography>
                <Typography variant="h6" fontWeight={'light'}>Field with role: {roleName}</Typography> */}
            </Box>
            {
                !canEntityHaveMembers ? null :
                <Box>
                    <Typography>{MEMBERS_NOTE}</Typography>
                </Box>
            }
            <Stack direction={'row'}>
                <PermissionsSectionForm
                    title={entityTypeName}
                    titleTooltip={`Permissions a user has for a ${entityTypeName} that they are the ${roleName} of.`}
                    expectedPerms={expectedPerms.root_section}
                    values={values.root_section}
                    setValue={(key, value) => setValue('root_section', key, value)}
                    setAllValues={x => setAllValues('root_section', x)}
                    />
                {
                    !canEntityHaveMembers ? null :
                    <PermissionsSectionForm
                        title={`${entityTypeName} Members`}
                        titleTooltip={`Permissions a user has for members of a ${entityTypeName} that they are the ${roleName} of.`}
                        expectedPerms={expectedPerms.member_section}
                        values={values.member_section}
                        setValue={(key, value) => setValue('member_section', key, value)}
                        setAllValues={x => setAllValues('member_section', x)}
                        />
                }
            {/* </Stack>
            <Stack direction={'row'}> */}
                {
                    !canRootEntitiesBeAccessor ? null :
                    <PermissionsSectionForm
                        title={`${entityTypeName} is Accessor`}
                        titleTooltip={`Permissions a user has regarding access where the user is the ${roleName} of the ${entityTypeName} that is the accessor of the access.`}
                        expectedPerms={expectedPerms.access_sections}
                        values={values.accessor_root_section}
                        setValue={(key, value) => setValue('accessor_root_section', key, value)}
                        setAllValues={x => setAllValues('accessor_root_section', x)}
                        />
                }
                {
                    !canMemberEntitiesBeAccessor ? null :
                    <PermissionsSectionForm
                        title={`${entityTypeName} Member is Accessor`}
                        titleTooltip={`Permissions a user has regarding access where the user is the ${roleName} of the ${entityTypeName} whose member is the accessor of the access.`}
                        expectedPerms={expectedPerms.access_sections}
                        values={values.accessor_member_section}
                        setValue={(key, value) => setValue('accessor_member_section', key, value)}
                        setAllValues={x => setAllValues('accessor_member_section', x)}
                        />
                }
                {
                    !canRootEntitiesBeResource ? null :
                    <PermissionsSectionForm
                        title={`${entityTypeName} is Resource`}
                        titleTooltip={`Permissions a user has regarding access where the user is the ${roleName} of the ${entityTypeName} that is the resource of the access.`}
                        expectedPerms={expectedPerms.access_sections}
                        values={values.resource_root_section}
                        setValue={(key, value) => setValue('resource_root_section', key, value)}
                        setAllValues={x => setAllValues('resource_root_section', x)}
                        />
                }
            </Stack>
            <Box marginTop={1}>
                <LoadButton onClick={onSaveClick} loading={isSubmitting} disabled={isSubmitting} variant={'contained'}>
                    Save
                </LoadButton>
            </Box>
        </Box>
    )

}

export default EditEntityRoleProfileView;
